@import url(https://fonts.googleapis.com/css?family=Catamaran:400,600,700,800&display=swap);
.Notice_notice__1sMPx {
  padding: 0.5rem 0.5rem 0.3rem;
  line-height: 1.0;
  margin-bottom: 1rem; }
  .Notice_notice__1sMPx .Notice_col_left__15Uq4 {
    display: inline-block;
    padding-right: 0;
    width: 20px;
    vertical-align: bottom; }
  .Notice_notice__1sMPx .Notice_col_right__1Y7PY {
    display: inline-block;
    padding-left: 0.5rem;
    width: 90%; }
  .Notice_notice__1sMPx .Notice_message__1voYj {
    line-height: 1.5;
    font-weight: 600; }
  .Notice_notice__1sMPx .Notice_icon_font__2daTq {
    font-size: 1.2rem; }
  .Notice_notice__1sMPx.Notice_error__2GB08 {
    color: #BC2025;
    border: 2px solid #BC2025; }
  .Notice_notice__1sMPx.Notice_success__2pmV8 {
    color: #29BBB3;
    border: 2px solid #29BBB3; }
  .Notice_notice__1sMPx.Notice_hide__2-9Yp {
    display: none; }

.ChangeWorkspace_change_workspace__1HaQd {
  position: absolute !important;
  background: white;
  left: 99%;
  top: 0;
  width: 250px;
  padding: 0;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.33);
  border: 0 !important;
  z-index: 5; }
  .ChangeWorkspace_change_workspace__1HaQd > li {
    padding: 0 !important; }
  .ChangeWorkspace_change_workspace__1HaQd .ChangeWorkspace_manage_workspaces__eABe7 {
    padding: 0.8rem 1.2rem !important;
    border-top: 1px solid #DDE0E1; }
    .ChangeWorkspace_change_workspace__1HaQd .ChangeWorkspace_manage_workspaces__eABe7:hover {
      background-color: #29BBB3;
      color: white; }
      .ChangeWorkspace_change_workspace__1HaQd .ChangeWorkspace_manage_workspaces__eABe7:hover a,
      .ChangeWorkspace_change_workspace__1HaQd .ChangeWorkspace_manage_workspaces__eABe7:hover span:before {
        color: white !important; }

.ChangeWorkspace_workspace_icon__21FGn {
  font-size: 1rem !important; }

.ChangeWorkspace_selected_div__3_KAC {
  display: inline-block;
  width: 12%; }

.ChangeWorkspace_label__16iq7 {
  display: inline-block;
  width: 88%;
  cursor: pointer; }

.ChangeWorkspace_small_header__2V-Eq {
  padding: 0.8rem;
  font-size: 10px;
  color: #29BBB3;
  text-transform: uppercase; }

.ChangeWorkspace_pharmacy_list__3ZZjG {
  padding-top: 0 !important;
  padding-bottom: 1.2rem !important;
  border: 0 !important; }

.ChangeWorkspace_pharmacy_no_listing__3MgjI {
  padding: 0.8rem 1.2rem;
  cursor: auto !important; }
  .ChangeWorkspace_pharmacy_no_listing__3MgjI:hover {
    color: #19242A; }

.ChangeWorkspace_pharmacy_listing__zahe- {
  padding: 0.8rem 1.2rem;
  cursor: pointer; }
  .ChangeWorkspace_pharmacy_listing__zahe- span {
    position: relative !important; }
  .ChangeWorkspace_pharmacy_listing__zahe-:hover {
    background-color: #29BBB3;
    color: white; }
    .ChangeWorkspace_pharmacy_listing__zahe-:hover span {
      color: white; }

#ChangeWorkspace_workspace_menu__2pbSM {
  display: none; }

.UserMenu_submenu__3ccCE {
  position: absolute;
  display: none;
  top: 1.1rem;
  left: 2rem;
  background: white;
  width: 190px;
  text-align: left;
  z-index: 50; }
  .UserMenu_submenu__3ccCE > ul {
    list-style: none;
    margin: 0;
    padding: 0 !important;
    border: 0 !important;
    border-bottom: 1px solid #DDE0E1 !important; }
    .UserMenu_submenu__3ccCE > ul > li {
      position: relative;
      color: #55646D;
      cursor: pointer;
      font-size: 0.8rem;
      padding: 1rem 1.2rem;
      font-weight: 600; }
      .UserMenu_submenu__3ccCE > ul > li div {
        position: relative; }
      .UserMenu_submenu__3ccCE > ul > li a {
        position: relative;
        display: block;
        color: #55646D;
        -webkit-transition-duration: 0s;
                transition-duration: 0s; }
      .UserMenu_submenu__3ccCE > ul > li span {
        position: absolute;
        margin-top: 2px;
        right: 0;
        font-size: .8rem; }
      .UserMenu_submenu__3ccCE > ul > li ul li {
        list-style: none; }
      .UserMenu_submenu__3ccCE > ul > li ul ul {
        padding: 0; }
    .UserMenu_submenu__3ccCE > ul > li:hover {
      background-color: #29BBB3; }
      .UserMenu_submenu__3ccCE > ul > li:hover > div > span,
      .UserMenu_submenu__3ccCE > ul > li:hover > a > span:before,
      .UserMenu_submenu__3ccCE > ul > li:hover > div,
      .UserMenu_submenu__3ccCE > ul > li:hover > a {
        color: white; }
      .UserMenu_submenu__3ccCE > ul > li:hover ul li a {
        color: #55646D !important; }
  .UserMenu_submenu__3ccCE > ul:last-child {
    border-bottom: 0 !important; }
  .UserMenu_submenu__3ccCE.UserMenu_open__36b3D {
    display: block; }

@media (min-width: 1200px) {
  .UserMenu_submenu__3ccCE {
    left: 1rem; } }

::-webkit-input-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #91999E; }

::-moz-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #91999E; }

::-ms-input-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #91999E; }

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #91999E; }

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #91999E; }

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #91999E; }

.Search_search_box__2QhXI {
  background-color: #E5E7E8;
  border: 0;
  border-radius: 2px;
  font-size: 1rem;
  padding: 7px 14px;
  padding-left: 20px;
  width: 100%; }

.Search_search_input_container__2YXGa {
  position: relative; }
  .Search_search_input_container__2YXGa span {
    position: absolute;
    top: 0.55rem;
    left: 1.4rem;
    font-size: 1.2rem;
    color: #91999E; }
  .Search_search_input_container__2YXGa input[type="text"] {
    padding-left: 2rem; }

.Search_filter_item__1U-Xd {
  position: relative;
  display: inline-block;
  margin-right: 1rem;
  border-radius: 18px; }
  .Search_filter_item__1U-Xd:hover, .Search_filter_item__1U-Xd.Search_active__1jnFh {
    background-color: #E5E7E8; }

.Search_selected__39x7z {
  font-weight: 700; }

.Search_template_filter__elxT6 {
  width: 210px; }

.Search_filter_term__3ScWM {
  display: inline-block;
  background: #29BBB3;
  padding: 0.3rem 0.6rem 0.2rem;
  margin-right: 0.5rem;
  margin-bottom: .5rem;
  color: white;
  font-size: 0.65rem;
  border-radius: 1rem;
  font-weight: 700;
  text-transform: uppercase; }
  .Search_filter_term__3ScWM span {
    margin-left: 0.5rem;
    font-size: 0.55rem;
    cursor: pointer; }

.Search_clear_all_filter__1hgwR {
  display: inline-block;
  cursor: pointer;
  margin-left: 1rem;
  color: #29BBB3;
  font-size: 0.7rem;
  font-weight: bold;
  border-bottom: 1px solid #29BBB3;
  text-transform: uppercase; }

.Search_total_results__1cliQ {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 0.7rem;
  text-align: right; }

.Search_search_full_width__1V7nB .Search_filter_item__1U-Xd {
  margin-top: 1rem; }

@media (min-width: 1200px) {
  .Search_filter_item__1U-Xd {
    padding: 0.5rem 0.8rem; } }

@media (min-width: 1450px) {
  .Search_filter_item__1U-Xd {
    padding: 0.5rem 1.1rem; }
  /*.search_input_container {
    flex: 0 0 58%;
    max-width: 58%;
  }*/
  /*.filters {
    flex: 0 0 42%;
    max-width: 42%;
  }*/ }

.Dashboard_dashboard__16WcZ {
  background-color: white; }
  .Dashboard_dashboard__16WcZ header {
    color: #42515A;
    font-size: 2rem;
    font-weight: 600;
    padding: 0 1rem; }
  .Dashboard_dashboard__16WcZ .Dashboard_dash_row__1JF54 {
    border-bottom: 1px solid #DDE0E1;
    padding: 30px 25px; }
    .Dashboard_dashboard__16WcZ .Dashboard_dash_row__1JF54.Dashboard_filter_row__1qAIp, .Dashboard_dashboard__16WcZ .Dashboard_dash_row__1JF54.Dashboard_templates_row__2FA6A {
      border-bottom: 0; }

.Dashboard_dashboard_container__cp3Md {
  padding-top: 0.90rem; }

.Dashboard_dashboard_templates__3cqcn {
  display: none; }

.Dashboard_dashboard_templates_open__3RmU0 {
  display: block; }

.Dashboard_dashboard_widget__3Jkvs {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-bottom: 2rem;
  flex: 0 0 50%;
  max-width: 50%; }
  .Dashboard_dashboard_widget__3Jkvs input[type=text] {
    width: 100%;
    padding: 0.45rem 0.5rem; }

.Dashboard_widget_container__3scX_ {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px; }

.Dashboard_end_results__3eA34 {
  position: relative;
  padding-bottom: 1.8rem;
  padding-top: 1rem;
  color: #91999E;
  font-size: 0.6rem;
  text-align: center;
  text-transform: uppercase;
  overflow: hidden; }
  .Dashboard_end_results__3eA34 .Dashboard_end_results_text__1rDf7 {
    position: absolute; }
    .Dashboard_end_results__3eA34 .Dashboard_end_results_text__1rDf7 span {
      width: 10%; }
    .Dashboard_end_results__3eA34 .Dashboard_end_results_text__1rDf7:before {
      content: '';
      position: relative;
      display: inline-block;
      top: -2px;
      margin-right: 1rem;
      border: 1px dashed #91999E;
      width: 41%; }
    .Dashboard_end_results__3eA34 .Dashboard_end_results_text__1rDf7:after {
      content: '';
      position: relative;
      display: inline-block;
      top: -2px;
      margin-left: 1rem;
      border: 1px dashed #91999E;
      width: 41%; }

@media (min-width: 1200px) {
  .Dashboard_end_results__3eA34 .Dashboard_end_results_text__1rDf7 span {
    width: 10%; }
  .Dashboard_end_results__3eA34 .Dashboard_end_results_text__1rDf7:before {
    width: 44%; }
  .Dashboard_end_results__3eA34 .Dashboard_end_results_text__1rDf7:after {
    width: 44%; } }

@media (min-width: 1200px) {
  .Dashboard_dashboard_templates__3cqcn {
    display: block; }
  .Dashboard_dashboard_widget__3Jkvs {
    flex: 0 0 100%;
    max-width: 100%;
    padding-right: 0; }
  .Dashboard_widget_container__3scX_ {
    position: fixed !important;
    padding-right: 15px; } }

.TemplatesListItem_template_item__1aefD {
  padding: 30px 25px;
  border-bottom: 1px solid #DDE0E1;
  align-items: center; }
  .TemplatesListItem_template_item__1aefD .TemplatesListItem_name__1acTI a {
    color: #42515A;
    font-size: 1.2rem;
    font-weight: 600;
    line-height: 1.3; }
  .TemplatesListItem_template_item__1aefD .TemplatesListItem_owner__3sVc6 {
    display: block;
    color: #91999E;
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.3; }
  .TemplatesListItem_template_item__1aefD .TemplatesListItem_format__WkL_i {
    color: #91999E;
    margin-top: 18px;
    font-size: 10px;
    font-weight: 500;
    text-transform: uppercase; }
    .TemplatesListItem_template_item__1aefD .TemplatesListItem_format__WkL_i .TemplatesListItem_icon_font__3Qv37 {
      display: inline-block;
      font-size: 1.45rem;
      width: 15%;
      margin-right: 0.2rem;
      vertical-align: top; }
    .TemplatesListItem_template_item__1aefD .TemplatesListItem_format__WkL_i div.TemplatesListItem_text_container__2cUSH {
      display: inline-block;
      width: 80%;
      line-height: 1.1;
      cursor: pointer; }
      .TemplatesListItem_template_item__1aefD .TemplatesListItem_format__WkL_i div.TemplatesListItem_text_container__2cUSH span {
        position: relative;
        top: 1px;
        display: inline;
        font-size: 0.7rem;
        margin-left: 0.2rem;
        color: #29BBB3; }
      .TemplatesListItem_template_item__1aefD .TemplatesListItem_format__WkL_i div.TemplatesListItem_text_container__2cUSH span.TemplatesListItem_text__DJODd {
        display: inline;
        color: #91999E;
        margin-left: 0;
        top: 0;
        font-size: 0.7rem;
        font-weight: 700;
        line-height: 0.7; }
  .TemplatesListItem_template_item__1aefD .TemplatesListItem_name__1acTI {
    flex: 0 0 33%;
    max-width: 33%; }
  .TemplatesListItem_template_item__1aefD .TemplatesListItem_owner__3sVc6 {
    flex: 0 0 100%;
    max-width: 100%; }
  .TemplatesListItem_template_item__1aefD .TemplatesListItem_format__WkL_i {
    flex: 0 0 30%;
    max-width: 30%; }
  .TemplatesListItem_template_item__1aefD .TemplatesListItem_links_container__jOLev {
    position: relative;
    flex: 0 0 25%;
    max-width: 25%;
    right: 0; }
  .TemplatesListItem_template_item__1aefD .TemplatesListItem_name__1acTI + .TemplatesListItem_owner__3sVc6 {
    flex: 0 0 23%;
    max-width: 23%; }
  .TemplatesListItem_template_item__1aefD .TemplatesListItem_owner__3sVc6 + .TemplatesListItem_format__WkL_i {
    flex: 0 0 23%;
    max-width: 23%; }
  .TemplatesListItem_template_item__1aefD .TemplatesListItem_owner__3sVc6 + .TemplatesListItem_format__WkL_i + .TemplatesListItem_links_container__jOLev {
    flex: 0 0 21%;
    max-width: 21%; }
  .TemplatesListItem_template_item__1aefD button {
    display: inline-block; }
  .TemplatesListItem_template_item__1aefD .TemplatesListItem_btn__2KRIw {
    margin-top: 7px; }
  .TemplatesListItem_template_item__1aefD a {
    display: inline-block; }
    .TemplatesListItem_template_item__1aefD a:hover {
      text-decoration: none; }
  .TemplatesListItem_template_item__1aefD .TemplatesListItem_create_note_btn__2yY-C {
    padding: 0.75rem 1rem;
    width: 100%; }
  .TemplatesListItem_template_item__1aefD .TemplatesListItem_edit_btn__3WMIk {
    background-color: white;
    border: 2px solid #BFC6CB;
    color: #AAB1B5; }

@media (min-width: 1024px) {
  .TemplatesListItem_template_item__1aefD .TemplatesListItem_format__WkL_i {
    margin-top: 0; }
  .TemplatesListItem_create_note_btn__2yY-C {
    padding: 0.75rem 1.5rem; } }

@media (min-width: 1336px) {
  .TemplatesListItem_template_item__1aefD .TemplatesListItem_format__WkL_i {
    display: block;
    margin-top: 0; }
  .TemplatesListItem_template_item__1aefD .TemplatesListItem_name__1acTI {
    font-size: 1.5rem; } }

@media (min-width: 1200px) {
  .TemplatesListItem_template_item__1aefD .TemplatesListItem_name__1acTI {
    flex: 0 0 45%;
    max-width: 45%; }
  .TemplatesListItem_template_item__1aefD .TemplatesListItem_format__WkL_i,
  .TemplatesListItem_template_item__1aefD .TemplatesListItem_links_container__jOLev {
    flex: 0 0 25%;
    max-width: 25%; }
  .TemplatesListItem_template_item__1aefD .TemplatesListItem_owner__3sVc6 + .TemplatesListItem_format__WkL_i,
  .TemplatesListItem_template_item__1aefD .TemplatesListItem_owner__3sVc6 + .TemplatesListItem_format__WkL_i + .TemplatesListItem_links_container__jOLev {
    flex: 0 0 16%;
    max-width: 16%; } }

@media (min-width: 1400px) {
  .TemplatesListItem_template_item__1aefD .TemplatesListItem_format__WkL_i,
  .TemplatesListItem_template_item__1aefD .TemplatesListItem_links_container__jOLev {
    flex: 0 0 25%;
    max-width: 25%; } }

.TemplateList_template_row_header__rMO4g {
  padding: 25px 25px 0; }

.TemplateList_header_templates__zqynR {
  flex: 0 0 33%;
  max-width: 33%; }

.TemplateList_header_format__g9EUd {
  flex: 0 0 30%;
  max-width: 30%; }

.TemplateList_header_btn__2O9Am {
  flex: 0 0 25%;
  max-width: 25%;
  padding-right: 0; }

.TemplateList_header_owner__2fDMn + .TemplateList_header_format__g9EUd {
  flex: 0 0 23%;
  max-width: 23%; }

.TemplateList_header_owner__2fDMn + .TemplateList_header_format__g9EUd + .TemplateList_header_btn__2O9Am {
  flex: 0 0 21%;
  max-width: 21%; }

.TemplateList_template_header__rqUP0 {
  color: #29BBB3;
  font-size: 0.75rem;
  font-weight: bold;
  text-transform: uppercase; }

.TemplateList_template_no_results__YGwCK {
  padding: 2rem; }

@media (min-width: 1200px) {
  .TemplateList_header_templates__zqynR {
    flex: 0 0 45%;
    max-width: 45%; }
  .TemplateList_header_owner__2fDMn {
    flex: 0 0 23%;
    max-width: 23%; }
  .TemplateList_header_format__g9EUd,
  .TemplateList_header_btn__2O9Am {
    flex: 0 0 25%;
    max-width: 25%; }
  .TemplateList_header_owner__2fDMn + .TemplateList_header_format__g9EUd,
  .TemplateList_header_owner__2fDMn + .TemplateList_header_format__g9EUd + .TemplateList_header_btn__2O9Am {
    flex: 0 0 16%;
    max-width: 16%; } }

.Widgets_widget_container__mVyiG {
  background-color: white;
  padding: 2.8rem;
  height: 100%;
  font-size: 0.93rem; }
  .Widgets_widget_container__mVyiG label {
    margin-top: 0; }
  .Widgets_widget_container__mVyiG input {
    margin-bottom: 1rem; }
  .Widgets_widget_container__mVyiG .Widgets_widget_btn__3DQJb {
    vertical-align: top; }
  .Widgets_widget_container__mVyiG .Widgets_widget_upgrade_message__3gBMC {
    display: inline-block;
    margin-left: 3%;
    margin-top: 0.6rem;
    width: 109%;
    max-width: 180px; }
  .Widgets_widget_container__mVyiG a {
    text-decoration: underline; }
    .Widgets_widget_container__mVyiG a:hover {
      text-decoration: underline; }

.Widgets_tablet_only__3h36G {
  display: block; }

@media (min-width: 1024px) {
  .Widgets_widget_container__mVyiG .Widgets_widget_upgrade_message__3gBMC {
    margin-top: 0;
    margin-left: 0;
    width: 39%; } }

@media (min-width: 1200px) {
  .Widgets_tablet_only__3h36G {
    display: none; }
  .Widgets_widget_container__mVyiG .Widgets_widget_upgrade_message__3gBMC {
    display: block;
    margin-top: 0.6rem;
    width: 100%; } }

@media (min-width: 1590px) {
  .Widgets_widget_container__mVyiG .Widgets_widget_upgrade_message__3gBMC {
    display: inline-block;
    margin-left: 3%;
    margin-top: 0;
    width: 31%; } }

@media (min-width: 1600px) {
  .Widgets_widget_container__mVyiG .Widgets_widget_upgrade_message__3gBMC {
    width: 35%; } }

@media (min-width: 1859px) {
  .Widgets_widget_container__mVyiG .Widgets_widget_upgrade_message__3gBMC {
    margin-top: 0.47rem;
    width: 40%; } }

.PageHeader_page_header__6XeBK {
  position: fixed;
  background: #19242A;
  color: white;
  padding: 2rem;
  margin-bottom: 1.5rem;
  z-index: 100; }
  .PageHeader_page_header__6XeBK .PageHeader_back_btn__1sHms {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    display: inline-block;
    width: 10%;
    padding: 0.8rem 0;
    font-size: 0.7rem;
    position: relative;
    vertical-align: top; }
  .PageHeader_page_header__6XeBK .PageHeader_page_title__Y4sdF {
    display: inline-block;
    width: 85%;
    padding-left: 0.7rem;
    font-size: 1.32rem;
    margin-right: 0.7rem;
    line-height: 1.0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%); }
  .PageHeader_page_header__6XeBK .PageHeader_template_name__1hopF {
    margin-right: 0.5rem; }
  .PageHeader_page_header__6XeBK .PageHeader_pharmacy_name__1_kXW {
    font-size: 1.1rem;
    color: #55646D; }
  .PageHeader_page_header__6XeBK .PageHeader_template_name__1hopF,
  .PageHeader_page_header__6XeBK .PageHeader_pharmacy_name__1_kXW {
    display: inline; }
  .PageHeader_page_header__6XeBK .PageHeader_header_right__4ZGDg {
    text-align: right; }
    .PageHeader_page_header__6XeBK .PageHeader_header_right__4ZGDg button {
      margin-left: 0.5rem; }
  .PageHeader_page_header__6XeBK .PageHeader_header_menu__pd5vH {
    display: inline-block; }
    .PageHeader_page_header__6XeBK .PageHeader_header_menu__pd5vH span {
      position: relative;
      top: 0.3rem;
      padding: 0.4rem;
      cursor: pointer; }
  .PageHeader_page_header__6XeBK .PageHeader_status__hhBOA {
    display: none;
    color: #42515A;
    font-size: 0.8rem;
    margin-right: 1rem; }
  .PageHeader_page_header__6XeBK .PageHeader_ui_btns__39K38 {
    display: none; }
    .PageHeader_page_header__6XeBK .PageHeader_ui_btns__39K38 button {
      padding: 0.75rem 1rem; }
  .PageHeader_page_header__6XeBK .PageHeader_text_link__1HDR8 {
    margin-right: 1rem;
    color: #29BBB3;
    font-size: .78rem;
    text-transform: uppercase;
    font-weight: bold;
    border-bottom: 1px solid #29BBB3;
    cursor: pointer; }

@media (min-width: 992px) {
  .PageHeader_header_right__4ZGDg .PageHeader_header_menu__pd5vH {
    display: none; }
  .PageHeader_header_right__4ZGDg .PageHeader_ui_btns__39K38 {
    display: block; }
    .PageHeader_header_right__4ZGDg .PageHeader_ui_btns__39K38 div:not(.PageHeader_status__hhBOA) {
      display: inline-block; } }

@media (min-width: 1200px) {
  .PageHeader_page_header__6XeBK .PageHeader_page_title__Y4sdF {
    font-size: 1.5rem; }
  .PageHeader_page_header__6XeBK .PageHeader_pharmacy_name__1_kXW {
    font-size: 1.25rem; }
  .PageHeader_page_header__6XeBK .PageHeader_header_right__4ZGDg .PageHeader_header_menu__pd5vH {
    display: none; }
  .PageHeader_page_header__6XeBK .PageHeader_header_right__4ZGDg .PageHeader_ui_btns__39K38 {
    display: block; }
    .PageHeader_page_header__6XeBK .PageHeader_header_right__4ZGDg .PageHeader_ui_btns__39K38 button {
      padding: 0.75rem 0.5rem; } }

@media (min-width: 1400px) {
  .PageHeader_page_header__6XeBK .PageHeader_header_right__4ZGDg .PageHeader_ui_btns__39K38 button {
    padding: 0.75rem 1.2rem; } }

.Template_template_section__3iVcD {
  padding-top: 140px; }

.Template_template__1RcJo {
  padding-top: 15px; }
  .Template_template__1RcJo .Template_edit_icon__7IzkX {
    position: relative;
    display: inline-block;
    right: 17px;
    width: 5%;
    cursor: pointer;
    font-size: 0.85rem;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%); }
  .Template_template__1RcJo .Template_note_header__35jvw {
    background: #ccc;
    background: url(/static/media/QlinicalRx_Header_large.82b44c79.png);
    background-size: 100% 100%;
    margin: 0;
    padding: 1.2rem 0.2rem; }
    .Template_template__1RcJo .Template_note_header__35jvw h2 {
      font-size: 1.4rem;
      margin-bottom: 0;
      font-weight: 600; }
    .Template_template__1RcJo .Template_note_header__35jvw .Template_small_header__3Tkpa {
      margin-top: 0; }
    .Template_template__1RcJo .Template_note_header__35jvw .Template_full_header__3pGmm {
      display: none; }
    .Template_template__1RcJo .Template_note_header__35jvw .Template_note_header_text__3aUdL {
      font-size: 1.5rem;
      font-weight: 600; }
    .Template_template__1RcJo .Template_note_header__35jvw .Template_datepicker__2FOtB {
      position: relative;
      background: transparent;
      border: 0;
      width: inherit;
      padding: 0 1rem 0 0;
      font-size: 1.5rem;
      font-weight: 600;
      z-index: 2; }
    .Template_template__1RcJo .Template_note_header__35jvw .Template_edit_icon__7IzkX {
      top: 1.5rem !important;
      -webkit-transform: translateY(0) !important;
              transform: translateY(0) !important; }
    .Template_template__1RcJo .Template_note_header__35jvw .Template_note_name__29U4U {
      padding-left: 10px; }
      .Template_template__1RcJo .Template_note_header__35jvw .Template_note_name__29U4U h2 {
        display: inline-block;
        width: 95%; }
      .Template_template__1RcJo .Template_note_header__35jvw .Template_note_name__29U4U input {
        width: 100%; }
    .Template_template__1RcJo .Template_note_header__35jvw .Template_note_name__29U4U,
    .Template_template__1RcJo .Template_note_header__35jvw .Template_note_date__1yJqh {
      position: relative;
      flex: 0 0 50%;
      max-width: 50%; }
  .Template_template__1RcJo .Template_template_content__1Zjeq {
    background-color: white;
    padding-top: 15px;
    padding-bottom: 15px; }
    .Template_template__1RcJo .Template_template_content__1Zjeq:first-child {
      margin-bottom: 1.5rem; }
  .Template_template__1RcJo .Template_no_pdf_settings__3Ph4R {
    display: none; }
  .Template_template__1RcJo .Template_block_list__2GyRr {
    margin-bottom: 4rem; }
    .Template_template__1RcJo .Template_block_list__2GyRr:last-child {
      margin-bottom: 0; }
  .Template_template__1RcJo .Template_section_name__3x7JV {
    font-size: 1.5rem;
    font-weight: 600; }
  .Template_template__1RcJo .Template_message_box__3cI3l {
    display: none;
    background-color: #F6BE15;
    color: white;
    padding: 1.2rem 1.2rem;
    margin-bottom: 1.5rem;
    font-weight: 700;
    font-size: 0.88rem; }
  .Template_template__1RcJo .Template_message_box_open__kS7RG {
    display: block; }
  .Template_template__1RcJo .Template_message_box_container__4BNMl {
    position: relative; }
  .Template_template__1RcJo .Template_message_box_message__3__jr {
    display: inline-block;
    width: 68%; }
  .Template_template__1RcJo .Template_message_box_close__2Vbq1 {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    right: 0;
    text-transform: uppercase;
    border-bottom: 1px solid white;
    cursor: pointer;
    line-height: 1.1; }
  .Template_template__1RcJo .Template_widget_box__1JDGd {
    background-color: white;
    margin-bottom: 1.5rem; }
    .Template_template__1RcJo .Template_widget_box__1JDGd .Template_widget_name__2j23L {
      border-bottom: 1px solid #DDE0E1;
      padding: 1.1rem 1.3rem;
      font-size: 1.3rem;
      font-weight: 600; }
      .Template_template__1RcJo .Template_widget_box__1JDGd .Template_widget_name__2j23L .Template_icon_font__3a-Gz {
        font-size: 0.8rem;
        float: right;
        padding: 0.5rem 0 0.5rem;
        cursor: pointer; }
  .Template_template__1RcJo .Template_template_sidebar__3Cx2Z {
    position: relative;
    width: inherit; }
  .Template_template__1RcJo .Template_template_sidebar_container__3IfLF {
    /*position: fixed;
    right: 15px;
    width: 28%;
    overflow: hidden;*/ }

@media (min-width: 1024px) {
  .Template_template__1RcJo .Template_template_content__1Zjeq {
    padding: 2.3rem; }
  .Template_template__1RcJo .Template_note_header__35jvw {
    padding: 2rem 1rem; }
    .Template_template__1RcJo .Template_note_header__35jvw .Template_full_header__3pGmm {
      display: block; }
    .Template_template__1RcJo .Template_note_header__35jvw .Template_mobile_header__OcqCF {
      display: none; }
    .Template_template__1RcJo .Template_note_header__35jvw .Template_note_name__29U4U {
      flex: 0 0 100%;
      max-width: 100%;
      margin-bottom: 1rem; }
    .Template_template__1RcJo .Template_note_header__35jvw .Template_note_date__1yJqh {
      padding-left: 10px; }
    .Template_template__1RcJo .Template_note_header__35jvw .Template_note_date__1yJqh {
      flex: 0 0 45%;
      max-width: 45%; }
    .Template_template__1RcJo .Template_note_header__35jvw .Template_full_header__3pGmm {
      flex: 0 0 50%;
      max-width: 50%; }
  .Template_message_box_message__3__jr {
    display: inline-block;
    width: 70%; }
  .Template_template_sidebar__3Cx2Z {
    position: relative;
    width: 34%;
    max-width: 34%; } }

@media (min-width: 1200px) {
  .Template_template__1RcJo .Template_note_header__35jvw h2 {
    font-size: 1.9rem;
    padding-right: 20px;
    margin-bottom: 0;
    font-weight: 600; } }

@media (min-width: 1500px) {
  .Template_template__1RcJo .Template_note_header__35jvw .Template_note_name__29U4U {
    flex: 0 0 50%;
    max-width: 50%;
    margin-bottom: 0; }
  .Template_template__1RcJo .Template_note_header__35jvw .Template_note_date__1yJqh {
    padding-left: 0; }
  .Template_template__1RcJo .Template_note_header__35jvw .Template_note_date__1yJqh {
    flex: 0 0 30%;
    max-width: 30%; }
  .Template_template__1RcJo .Template_note_header__35jvw .Template_full_header__3pGmm {
    flex: 0 0 20%;
    max-width: 20%; } }

.Block_block__2-HEg {
  margin-bottom: 1rem; }
  .Block_block__2-HEg .Block_name__3wNdr {
    color: #4A4A4A;
    font-size: 1.08rem;
    font-weight: 600;
    margin-bottom: 0.8rem; }
  .Block_block__2-HEg .Block_tool_tip_container__2-yLH {
    position: relative;
    display: inline-block;
    margin-left: 0.2rem; }
    .Block_block__2-HEg .Block_tool_tip_container__2-yLH span {
      color: #29BBB3;
      font-size: 0.9rem;
      cursor: pointer; }
  .Block_block__2-HEg.Block_hide_block__2E8KL {
    display: none; }

@media (min-width: 564px) {
  .Block_grid_h__1qBND .Block_checkbox_field__oo88r,
  .Block_grid_h__1qBND .Block_radio_field__2bPoi,
  .Block_grid_h__1qBND .Block_input_group_field__-nT2X {
    display: inline-block;
    width: 50%; } }

@media (min-width: 1024px) {
  .Block_grid_h__1qBND .Block_checkbox_field__oo88r,
  .Block_grid_h__1qBND .Block_radio_field__2bPoi {
    display: inline-block; } }

@media (min-width: 1336px) {
  .Block_grid_h__1qBND .Block_checkbox_field__oo88r,
  .Block_grid_h__1qBND .Block_radio_field__2bPoi,
  .Block_grid_h__1qBND .Block_input_group_field__-nT2X {
    display: inline-block;
    width: 33.333%; } }

@media (min-width: 1500px) {
  .Block_grid_h__1qBND .Block_checkbox_field__oo88r,
  .Block_grid_h__1qBND .Block_radio_field__2bPoi,
  .Block_grid_h__1qBND .Block_input_group_field__-nT2X {
    display: inline-block;
    width: 25%; } }

.Inputs_hide_input__2IRb2 {
  display: none; }

.Inputs_text_multiline__30j3G {
  width: 100%; }
  .Inputs_text_multiline__30j3G textarea {
    width: 100%; }

/*.input_group {
  //margin-bottom: 0.5rem;
  vertical-align: top;

  .group_item {
    display: inline-block;
    vertical-align: middle;
  }

  .group_item:last-child {
    //width: 50%;
    margin-right: 0;
  }

  .group_item:nth-child(2),
  .group_item:nth-child(3) {
    margin-left: 0.5rem;
  }
}*/

.Outline_outline__3gQUZ {
  list-style: none;
  margin: 0;
  padding: 0;
  max-height: 0;
  overflow: hidden;
  -webkit-transition: 0.5s;
  transition: 0.5s; }
  .Outline_outline__3gQUZ li {
    list-style: none; }
    .Outline_outline__3gQUZ li span {
      display: block;
      padding: 0.8rem 1.3rem;
      font-size: 1rem;
      cursor: pointer; }
    .Outline_outline__3gQUZ li ul {
      margin: 0;
      padding: 0; }
      .Outline_outline__3gQUZ li ul li span {
        padding-left: 1.2rem; }
  .Outline_outline__3gQUZ > li > span {
    font-weight: 600; }
  .Outline_outline__3gQUZ > li > ul > li > span:hover,
  .Outline_outline__3gQUZ > li > span:hover,
  .Outline_outline__3gQUZ li.Outline_selected__2JcXB span {
    background-color: #29BBB3;
    color: white; }
  .Outline_outline__3gQUZ.Outline_open__RsdAA {
    max-height: 700px; }

@media (min-width: 1024px) {
  .Outline_outline__3gQUZ li span {
    font-size: 1.07rem; }
  .Outline_outline__3gQUZ li ul li span {
    padding-left: 2.7rem; } }

.PdfSettingsOutline_settings_container__3tTiC {
  padding: 1.3rem; }

.PdfSettingsOutline_pdf_settings__1ziXn {
  max-height: 0;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  overflow: hidden; }
  .PdfSettingsOutline_pdf_settings__1ziXn.PdfSettingsOutline_open__2-bO5 {
    max-height: 700px;
    overflow: visible; }
  .PdfSettingsOutline_pdf_settings__1ziXn .PdfSettingsOutline_search_box__TOH45 {
    background-color: #E5E7E8;
    border: 0;
    border-radius: 2px;
    font-size: 1rem;
    padding: 7px 14px;
    padding-left: 20px;
    width: 100%;
    margin-bottom: 0.8rem; }
  .PdfSettingsOutline_pdf_settings__1ziXn .PdfSettingsOutline_setting_search__3egTG {
    position: relative; }
    .PdfSettingsOutline_pdf_settings__1ziXn .PdfSettingsOutline_setting_search__3egTG span {
      position: absolute;
      top: 0.6rem;
      left: 0.5rem;
      font-size: 1.1rem;
      color: #91999E; }
    .PdfSettingsOutline_pdf_settings__1ziXn .PdfSettingsOutline_setting_search__3egTG input {
      padding-left: 2rem;
      width: 100%; }
    .PdfSettingsOutline_pdf_settings__1ziXn .PdfSettingsOutline_setting_search__3egTG ::-webkit-input-placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      font-size: 0.9rem; }
    .PdfSettingsOutline_pdf_settings__1ziXn .PdfSettingsOutline_setting_search__3egTG ::-moz-placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      font-size: 0.9rem; }
    .PdfSettingsOutline_pdf_settings__1ziXn .PdfSettingsOutline_setting_search__3egTG ::-ms-input-placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      font-size: 0.9rem; }
    .PdfSettingsOutline_pdf_settings__1ziXn .PdfSettingsOutline_setting_search__3egTG ::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      font-size: 0.9rem; }
    .PdfSettingsOutline_pdf_settings__1ziXn .PdfSettingsOutline_setting_search__3egTG :-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      font-size: 0.9rem; }
    .PdfSettingsOutline_pdf_settings__1ziXn .PdfSettingsOutline_setting_search__3egTG ::-ms-input-placeholder {
      /* Microsoft Edge */
      font-size: 0.9rem; }
  .PdfSettingsOutline_pdf_settings__1ziXn .PdfSettingsOutline_setting_meta__3f1ds {
    display: flex; }
  .PdfSettingsOutline_pdf_settings__1ziXn .PdfSettingsOutline_setting_filters__34wmJ {
    flex: 0 0 80%;
    max-width: 80%; }
  .PdfSettingsOutline_pdf_settings__1ziXn .PdfSettingsOutline_setting_results__UmsTK {
    flex: 0 0 20%;
    max-width: 20%;
    font-size: 0.88rem;
    font-weight: 600;
    margin: 0.5rem 0;
    text-align: right; }
  .PdfSettingsOutline_pdf_settings__1ziXn .PdfSettingsOutline_setting_item__3Nn9D {
    line-height: 1.5;
    padding: 0.8rem 0 0.5rem;
    border-bottom: 1px solid #DDE0E1; }
  .PdfSettingsOutline_pdf_settings__1ziXn .PdfSettingsOutline_setting_header__1C7UT,
  .PdfSettingsOutline_pdf_settings__1ziXn .PdfSettingsOutline_setting_toggle__2nJ5H {
    min-height: 40px; }
  .PdfSettingsOutline_pdf_settings__1ziXn .PdfSettingsOutline_setting_header__1C7UT {
    padding-right: 0;
    font-weight: 600;
    font-size: 1.05rem;
    line-height: 1.2; }
    .PdfSettingsOutline_pdf_settings__1ziXn .PdfSettingsOutline_setting_header__1C7UT .PdfSettingsOutline_category__USkSr {
      font-weight: 400;
      font-size: 0.9rem; }
  .PdfSettingsOutline_pdf_settings__1ziXn .PdfSettingsOutline_setting_toggle__2nJ5H {
    color: #29BBB3;
    text-transform: uppercase;
    text-decoration: underline;
    font-size: 0.68rem;
    font-weight: 800;
    cursor: pointer;
    align-items: center;
    display: flex;
    text-align: right; }

@media (min-width: 992px) {
  .PdfSettingsOutline_settings_container__3tTiC {
    padding: 2rem; } }

@media (min-width: 1200px) {
  .PdfSettingsOutline_setting_toggle__2nJ5H {
    justify-content: right; } }

.CustomTemplatesList_row_item__3C_IO {
  align-items: center;
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #DDE0E1; }

.CustomTemplatesList_header_check__1j6Fh,
.CustomTemplatesList_check__3nTNR {
  flex: 0 0 3%;
  max-width: 3%;
  padding-right: 0; }

.CustomTemplatesList_header_templates__1HUF-,
.CustomTemplatesList_name__2EfOi {
  flex: 0 0 20%;
  max-width: 20%;
  justify-content: center;
  align-content: center; }

.CustomTemplatesList_header_owner__2IJYP,
.CustomTemplatesList_owner__2i_RA {
  flex: 0 0 15%;
  max-width: 15%; }

.CustomTemplatesList_header_creator__OCgmo,
.CustomTemplatesList_creator__3zaeH {
  flex: 0 0 10%;
  max-width: 10%; }

.CustomTemplatesList_header_date__1oqEZ,
.CustomTemplatesList_date__1Pcy2 {
  flex: 0 0 13.5%;
  max-width: 13.5%; }

.CustomTemplatesList_header_btn__1F0a5 {
  flex: 0 0 10%;
  max-width: 10%;
  padding-right: 0; }

/*.check,
.name,
.owner,
.creator,
.date,
.link {
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid $border-grey;
}*/
.CustomTemplatesList_link__2LEc9 {
  flex: 0 0 11%;
  max-width: 11%;
  padding-right: 0; }

.CustomTemplatesList_edit_template__2vH97 {
  padding-left: 0; }

.CustomTemplatesList_name__2EfOi a {
  color: #42515A; }

.CustomTemplatesList_name__2EfOi,
.CustomTemplatesList_owner__2i_RA {
  font-size: 17px; }

.CustomTemplatesList_create_note_link__rAxUo {
  font-weight: bold;
  text-transform: uppercase;
  text-decoration: underline; }

.CardTemplate_card_block__2nIb8 {
  padding: 1rem 0 1rem 1rem;
  border-bottom: 1px solid #DDE0E1;
  margin-left: 0;
  margin-right: 0; }
  .CardTemplate_card_block__2nIb8:last-child {
    border-bottom: 0; }

.CardTemplate_owner_text__2UuwZ {
  font-size: .9rem; }

.CardTemplate_setting_header__3J633 {
  padding-right: 0;
  font-weight: 600;
  font-size: 1.05rem;
  line-height: 1.2; }
  .CardTemplate_setting_header__3J633 .CardTemplate_category__3d0ZR {
    font-weight: 400;
    font-size: 0.9rem; }

.CardTemplate_setting_toggle__1YTcZ {
  padding-right: 0;
  color: #29BBB3;
  text-transform: uppercase;
  text-decoration: underline;
  font-size: 0.68rem;
  font-weight: 800;
  cursor: pointer;
  align-items: center;
  display: flex;
  justify-content: right; }

.CustomBlock_customBlockList__3C6GE {
  background: #F3F3F3;
  padding: 15px;
  margin-bottom: 2rem; }

.CustomBlock_customBlock__2yeF1 {
  background-color: white;
  padding: 1rem;
  margin-bottom: 1.5rem;
  border-left: 3px solid #29BBB3; }
  .CustomBlock_customBlock__2yeF1 .CustomBlock_dragIcon__3m9Wv {
    color: #BBBBBB;
    cursor: move; }
  .CustomBlock_customBlock__2yeF1 .CustomBlock_closeIcon__2cGLw {
    color: #29BBB3;
    font-size: .7rem;
    cursor: pointer; }
  .CustomBlock_customBlock__2yeF1 .CustomBlock_colRow__1mBfO {
    display: flex; }
  .CustomBlock_customBlock__2yeF1 .CustomBlock_colLeft__dWOF8 {
    flex: 0 0 5%;
    max-width: 5%; }
  .CustomBlock_customBlock__2yeF1 .CustomBlock_colRight__3mIdM {
    flex: 0 0 95%;
    max-width: 95%; }
    .CustomBlock_customBlock__2yeF1 .CustomBlock_colRight__3mIdM h4 {
      display: inline-block; }

.CustomBlock_default_value__2lXex {
  display: inline-block;
  margin-left: 1.4rem;
  color: #F6BE15;
  line-height: 1.2;
  font-size: 0.85rem;
  font-weight: bold;
  text-transform: uppercase;
  border-bottom: 1px solid #F6BE15;
  cursor: pointer; }

.CustomBlock_checkbox-input__288qj input[type="checkbox"]:checked + label::before {
  background-color: #F6BE15; }

.EditCustomTemplate_template_section__2kFhc {
  padding-top: 140px; }

.EditCustomTemplate_section_name__pTVO7 {
  font-size: 1.5rem;
  font-weight: 600; }

.EditCustomTemplate_basic_information__2aCaF {
  background: white;
  padding: 25px;
  margin-bottom: 25px; }
  .EditCustomTemplate_basic_information__2aCaF input {
    width: 100%; }

.EditCustomTemplate_template_content__15HWL {
  background: white;
  padding: 25px;
  margin-bottom: 25px; }

.EditCustomTemplate_pdf_content__1N3ao {
  background: white;
  padding: 25px; }

.Mobile_mobile_view__2YYfA {
  position: fixed;
  background-color: #19242A;
  color: white;
  width: 100%;
  height: 100%;
  left: 0;
  font-size: 0.88rem;
  text-align: center; }
  .Mobile_mobile_view__2YYfA .Mobile_mobile_content__3Xq42 {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    position: relative; }
    .Mobile_mobile_view__2YYfA .Mobile_mobile_content__3Xq42 .Mobile_logo___1tme {
      margin-bottom: 2rem; }
    .Mobile_mobile_view__2YYfA .Mobile_mobile_content__3Xq42 .Mobile_message__1FsUu {
      padding: 0 3rem;
      margin-bottom: 5rem;
      font-weight: 600; }
    .Mobile_mobile_view__2YYfA .Mobile_mobile_content__3Xq42 .Mobile_pharmacy_name__11RiK {
      margin-top: 0.2rem;
      font-size: 1rem;
      font-weight: 600; }
    .Mobile_mobile_view__2YYfA .Mobile_mobile_content__3Xq42 .Mobile_username__2kKyd {
      text-transform: uppercase;
      margin-bottom: 2rem;
      font-size: 0.9rem; }
    .Mobile_mobile_view__2YYfA .Mobile_mobile_content__3Xq42 .Mobile_button_link__2JRJH a {
      padding: 0.5rem 1.2rem !important; }
  .Mobile_mobile_view__2YYfA .Mobile_help_link__CaB9U {
    position: absolute;
    text-align: center;
    width: 100%;
    bottom: 3rem;
    font-size: 1rem;
    font-weight: 600; }
    .Mobile_mobile_view__2YYfA .Mobile_help_link__CaB9U a {
      color: #55646D; }
    .Mobile_mobile_view__2YYfA .Mobile_help_link__CaB9U span {
      display: inline-block;
      padding-bottom: 0.1rem;
      margin-right: 0.4rem;
      font-size: 1.2rem;
      vertical-align: middle; }

@font-face {
  font-family: 'icomoon';
  src: url(/static/media/icomoon.636b0b9c.eot);
  src: url(/static/media/icomoon.636b0b9c.eot#iefix) format("embedded-opentype"), url(/static/media/icomoon.933fcf60.ttf) format("truetype"), url(/static/media/icomoon.4e91415e.woff) format("woff"), url(/static/media/icomoon.fc50847b.svg#icomoon) format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block; }

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-opennew:before {
  content: "\E926";
  color: #91999e; }

.icon-Circle_Checkmark .path1:before {
  content: "\E90A";
  color: #29bbb3; }

.icon-Circle_Checkmark .path2:before {
  content: "\E923";
  margin-left: -1em;
  color: white; }

.icon-Circle_X .path1:before {
  content: "\E924";
  color: #bc2025; }

.icon-Circle_X .path2:before {
  content: "\E925";
  margin-left: -1em;
  color: white; }

.icon-Back-Arrow:before {
  content: "\E909"; }

.icon-Finish_Save:before {
  content: "\E913"; }

.icon-Kroll:before {
  content: "\E914"; }

.icon-pdf:before {
  content: "\E915"; }

.icon-text:before {
  content: "\E916"; }

.icon-CustomForm:before {
  content: "\E917"; }

.icon-Dashboard:before {
  content: "\E918"; }

.icon-Edit:before {
  content: "\E919"; }

.icon-Expand:before {
  content: "\E91A"; }

.icon-Help:before {
  content: "\E91B"; }

.icon-NewNote:before {
  content: "\E91C"; }

.icon-OpenSaved:before {
  content: "\E91D"; }

.icon-Profile:before {
  content: "\E91E"; }

.icon-Settings:before {
  content: "\E91F"; }

.icon-Upgrade_1:before {
  content: "\E920"; }

.icon-Upgrade_2:before {
  content: "\E921"; }

.icon-Upgrade_3:before {
  content: "\E922"; }

.icon-Arrow:before {
  content: "\E900"; }

.icon-Arrow_Line:before {
  content: "\E901"; }

.icon-Checkmark:before {
  content: "\E902"; }

.icon-Delete:before {
  content: "\E903"; }

.icon-DotMenu:before {
  content: "\E904"; }

.icon-DragIcon:before {
  content: "\E905"; }

.icon-DropDown:before {
  content: "\E906"; }

.icon-Duplicate:before {
  content: "\E907"; }

.icon-Filter:before {
  content: "\E908"; }

.icon-Info:before {
  content: "\E90B"; }

.icon-Plus:before {
  content: "\E90C"; }

.icon-Primary:before {
  content: "\E90D"; }

.icon-Search:before {
  content: "\E90E"; }

.icon-Sort:before {
  content: "\E90F"; }

.icon-Triangle:before {
  content: "\E910"; }

.icon-X_Bigger:before {
  content: "\E911"; }

.icon-X_CloseForm:before {
  content: "\E912"; }

@charset "UTF-8";
.checkbox-input {
  position: relative; }
  .checkbox-input input[type="checkbox"] {
    position: absolute;
    opacity: 0;
    width: 16px;
    height: 16px;
    top: 3px;
    z-index: 3; }
  .checkbox-input label {
    position: relative;
    display: inline-block;
    padding-left: 28px;
    vertical-align: top; }
    .checkbox-input label::before {
      content: '';
      background-color: #E5E7E8;
      display: inline-block;
      height: 16px;
      width: 16px;
      top: 3px;
      left: 0; }
    .checkbox-input label::after {
      content: '\E902';
      display: inline-block;
      font-family: 'icomoon' !important;
      font-style: normal;
      font-weight: normal;
      -webkit-font-feature-settings: normal;
              font-feature-settings: normal;
      font-variant: normal;
      text-transform: none;
      font-size: 0.84rem;
      line-height: 1;
      top: 5px;
      left: 2px;
      color: white;
      height: 16px;
      width: 16px; }
  .checkbox-input label::before,
  .checkbox-input label::after {
    position: absolute; }
  .checkbox-input input[type="checkbox"] + label::after {
    content: none; }
  .checkbox-input input[type="checkbox"]:checked + label::after {
    content: "\E902" !important; }
  .checkbox-input input[type="checkbox"]:checked + label::before {
    background-color: #29BBB3; }

.custom-templates .checkbox-input input[type="checkbox"]:checked + label::before {
  background-color: #F6BE15; }

.date-input label {
  display: inline-block; }

.date-input .react-datepicker-wrapper {
  width: 50%; }

.date-input .react-datepicker-popper {
  z-index: 50; }

.date-input input[type="text"] {
  padding: 0.3rem 0.5rem 0;
  border: 0;
  border-bottom: 1px solid #42515A; }

.input-group {
  align-items: center; }

.group-item {
  width: 100%; }

/*.group-item-text {
  display: block;
  width: 100%;
}

.group-item-input-text {
  .text-input { margin-bottom: 0; }
  .no-label-before.no-label-after { width: 100%; }
}

.group-item-checkbox {
  //width: 8%;

  .checkbox-input label.empty-label {
    padding-left: 20px;
  }
}

.group-item-checkbox + .group-item-select {
  width: 33%;
}

.group-item-select:last-child {
  width: 80%;
}

.group-item-checkbox.has-label + .group-item-select:last-child {
  width: 70%;
}

/*.group-item-checkbox.has-label + .group-item-input-text:last-child {
  width: 40%;
}*/
/*.group-item-radio + .group-item-date:last-child {
  width: 50%;
}*/
/*.group-item-checkbox + .group-item-input-text,
.group-item-radio + .group-item-input-text,
.group-item-radio + .group-item-select {
  margin-left: 0.5rem;
}

.group-item-date {
  width: 100%;

  .react-datepicker-wrapper {
    margin-left: 0.5rem;
    width: 30%;
  }
}*/
.input-radio-input-text input[type=radio]:checked ~ input[type=text] {
  border-color: #29BBB3; }

.input-checkbox-input-text input[type=checkbox]:checked + input[type=text] {
  border-color: #29BBB3; }

.input-checkbox-input-select .checkbox-input,
.input-checkbox-input-select .select-input {
  display: inline-block; }

.input-checkbox-input-select .checkbox-input {
  width: 9%; }

.input-checkbox-input-select .select-input {
  width: 79%; }

.grid_v .group-item {
  width: 100%; }

.grid_v .input-checkbox-input-text .text-input,
.grid_v .input-radio-input-text .text-input {
  display: inline-block;
  padding-left: .5rem;
  width: 60%; }
  .grid_v .input-checkbox-input-text .text-input input[type="text"],
  .grid_v .input-radio-input-text .text-input input[type="text"] {
    width: 100%; }

.grid_v .input-checkbox-input-text .checkbox-input,
.grid_v .input-checkbox-input-date .checkbox-input {
  display: inline-block; }

.grid_v .input-radio-input-text .radio-input,
.grid_v .input-radio-input-date .radio-input {
  display: inline-block; }

.grid_v .input-checkbox-input-date .date-input,
.grid_v .input-radio-input-date .date-input {
  display: inline-block;
  margin-left: .5rem; }

.grid_v .input-radio-input-text-input-date .radio-input {
  display: inline-block; }

.grid_v .input-radio-input-text-input-date .text-input {
  display: inline-block;
  padding-left: .5rem;
  width: 50%; }
  .grid_v .input-radio-input-text-input-date .text-input input[type="text"] {
    width: 100%; }

.grid_v .input-radio-input-text-input-date .date-input {
  display: inline-block;
  margin-left: 1.4rem; }

.grid_v .input-date-input-select .date-input {
  display: inline-block;
  margin-bottom: .5rem; }

.grid_h .input-checkbox-input-text .group-item {
  width: 100%; }

.grid_h .input-checkbox-input-text .has-label,
.grid_h .input-checkbox-input-text .checkbox-input {
  width: 100%; }

.grid_h .input-checkbox-input-text .text-input {
  width: 90%;
  padding-left: 28px; }
  .grid_h .input-checkbox-input-text .text-input input[type="text"] {
    width: 100%; }

.grid_h .input-radio-input-text .group-item {
  width: 100%; }

.grid_h .input-radio-input-text .has-label,
.grid_h .input-radio-input-text .radio-input {
  width: 100%; }

.grid_h .input-radio-input-text .text-input {
  width: 90%;
  padding-left: 28px; }
  .grid_h .input-radio-input-text .text-input input[type="text"] {
    width: 100%; }

.radio-input .radio_option {
  position: relative; }
  .radio-input .radio_option input[type="radio"] {
    position: absolute;
    opacity: 0;
    width: 15px;
    height: 15px;
    top: 3px;
    width: 100px;
    z-index: 3; }
  .radio-input .radio_option label {
    position: relative;
    display: inline-block;
    padding-left: 22px;
    margin-bottom: 0.2rem; }
    .radio-input .radio_option label::before {
      content: '';
      background-color: #E5E7E8;
      display: inline-block;
      height: 16px;
      width: 16px;
      top: 3px;
      left: 0;
      border-radius: 8px; }
    .radio-input .radio_option label::after {
      content: '';
      display: inline-block;
      background-color: #29BBB3;
      line-height: 1;
      top: 6px;
      left: 0.185rem;
      color: white;
      width: 10px;
      height: 10px;
      border-radius: 5px; }
  .radio-input .radio_option label::before,
  .radio-input .radio_option label::after {
    position: absolute; }
  .radio-input .radio_option input[type="radio"] + label::after {
    background-color: transparent; }
  .radio-input .radio_option input[type="radio"]:checked + label::after {
    background-color: #29BBB3; }
  .radio-input .radio_option input[type="checkbox"]:checked + label::before {
    background-color: #29BBB3; }

select,
.select_input {
  border: 1px solid #DDE0E1;
  border-radius: 2px;
  font-size: 0.88rem;
  width: 100%; }
  select div,
  .select_input div {
    border: 0; }
  select .css-yk16xz-control,
  select .css-1pahdxg-control,
  .select_input .css-yk16xz-control,
  .select_input .css-1pahdxg-control {
    max-height: 30px;
    min-height: 30px; }
  select .css-1wa3eu0-placeholder,
  .select_input .css-1wa3eu0-placeholder {
    color: #42515A; }
  select svg,
  .select_input svg {
    fill: #29BBB3; }
  select .css-tlfecz-indicatorContainer,
  select .css-1gtu0rj-indicatorContainer,
  .select_input .css-tlfecz-indicatorContainer,
  .select_input .css-1gtu0rj-indicatorContainer {
    padding: 3px 5px; }
  select .css-1okebmr-indicatorSeparator,
  .select_input .css-1okebmr-indicatorSeparator {
    display: none; }
  select .css-yk16xz-control,
  select .css-1pahdxg-control,
  .select_input .css-yk16xz-control,
  .select_input .css-1pahdxg-control {
    border-color: #29BBB3;
    outline-color: #29BBB3; }
    select .css-yk16xz-control:active, select .css-yk16xz-control:focus,
    select .css-1pahdxg-control:active,
    select .css-1pahdxg-control:focus,
    .select_input .css-yk16xz-control:active,
    .select_input .css-yk16xz-control:focus,
    .select_input .css-1pahdxg-control:active,
    .select_input .css-1pahdxg-control:focus {
      border-color: #29BBB3; }
    select .css-yk16xz-control:hover,
    select .css-1pahdxg-control:hover,
    .select_input .css-yk16xz-control:hover,
    .select_input .css-1pahdxg-control:hover {
      border-color: #29BBB3;
      box-shadow: 0 0 0 1px #29BBB3; }

.select-input label {
  width: 15%; }

.select-input label + select {
  width: 80%; }

input[type=password] {
  padding: .4rem .5rem;
  border: 1px solid #DDE0E1;
  border-radius: 2px; }

.label-after {
  margin-left: 0.5rem; }

textarea {
  border: 1px solid #DDE0E1; }

.text-input {
  width: 100%; }
  .text-input input[type="text"] {
    background-color: #FFFBF2;
    padding: 0.3rem 0.5rem 0;
    border: 0;
    border-bottom: 1px solid #42515A; }
    .text-input input[type="text"].has-label-after {
      width: 60% !important; }
  .text-input .label-before,
  .text-input .label-after {
    display: inline-block; }
  .text-input .label-after {
    margin-left: 0.3rem; }

input[type="text"] {
  padding: 0.3rem 0.5rem; }

.input-timepicker input {
  width: 200px; }

.react-time-picker__inputGroup__input {
  padding: 5px;
  width: 15px !important;
  min-width: 0.54em;
  height: 100%;
  position: relative;
  padding: 0 1px;
  border: 0;
  background: none;
  font: inherit;
  box-sizing: content-box;
  -webkit-appearance: textfield;
  -moz-appearance: textfield; }

.react-time-picker__inputGroup__divider {
  padding-right: 1px; }

.input-medication-block {
  margin-bottom: 2.5rem;
  overflow: hidden; }

.med-block-header {
  padding: 5px;
  width: 10%;
  border-top: 1px solid #DDE0E1; }

.med-block-items-row {
  border-bottom: 1px solid #DDE0E1; }

.med-block-items {
  margin-bottom: 1.5rem;
  border-left: 1px solid #DDE0E1;
  border-right: 1px solid #DDE0E1; }
  .med-block-items .item-col {
    width: 20%;
    padding: 10px 5px;
    vertical-align: middle; }

.med-block-header .val-col, .med-block-header.val-col,
.med-block-items .val-col,
.med-block-items.val-col {
  width: 10%; }

.med-block-header .therapy-col, .med-block-header.therapy-col,
.med-block-header .current-col, .med-block-header.current-col,
.med-block-items .therapy-col,
.med-block-items.therapy-col,
.med-block-items .current-col,
.med-block-items.current-col {
  width: 15%; }

.hide_input {
  display: none; }

.input-container {
  margin-bottom: 1rem; }

label + input[type="text"],
label + select {
  margin-left: 0.5rem; }

label {
  margin-bottom: 0; }

.top-label {
  color: #29BBB3;
  font-size: 0.65rem;
  font-weight: 900;
  text-transform: uppercase; }

input[type=text]:focus,
select:focus {
  outline-color: #29BBB3;
  border-color: #29BBB3; }

.input-field {
  margin-bottom: .35rem; }

.no_gutter {
  padding-left: 0 !important;
  padding-right: 0 !important; }

hr {
  border-color: #DDE0E1; }

.text_uppercase {
  text-transform: uppercase; }

.colour-teal {
  color: #29BBB3; }

.btn-primary {
  background-color: #29BBB3;
  color: white;
  border-color: transparent; }

.btn.disabled {
  background-color: #D5D8DA;
  color: #91999E; }

.icon-small {
  margin-left: .5rem;
  font-size: 0.6rem;
  vertical-align: baseline; }

h4 {
  font-size: 1.08rem;
  font-weight: 600; }

.semi-bold {
  font-weight: 600; }

.message-box {
  position: relative;
  background: #f6b515;
  background: -webkit-gradient(linear, left top, right top, from(#f6b515), to(#f69615));
  background: -webkit-linear-gradient(left, #f6b515 0%, #f69615 100%);
  background: linear-gradient(90deg, #f6b515 0%, #f69615 100%);
  padding: 2rem;
  margin-bottom: 1rem;
  color: white;
  font-size: 0.9rem; }
  .message-box a {
    color: white !important;
    text-decoration: underline; }
  .message-box .col-left {
    align-self: center; }
  .message-box .col-right {
    text-align: right; }
  .message-box .upgrade-btn {
    background-color: transparent !important;
    border: 2px solid white;
    padding: 0.75rem 0.7rem !important;
    line-height: 1;
    text-transform: uppercase; }

.paper-format {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%); }

.react-datepicker-wrapper,
.react-datepicker__input-container {
  width: 100%; }

.react-datepicker-popper {
  width: 400px; }

.login-container {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  width: 100%;
  left: 0; }
  .login-container .login-wrapper {
    max-width: 450px;
    margin: 0 auto; }
  .login-container .login-logo {
    margin-bottom: 3.2rem;
    text-align: center; }
  .login-container .login-box {
    background-color: white;
    padding: 2.5rem;
    margin-bottom: 2rem; }
    .login-container .login-box h2 {
      font-weight: 600; }
    .login-container .login-box input {
      width: 100%; }
    .login-container .login-box input[type="password"] {
      margin-bottom: 1rem; }
    .login-container .login-box button {
      margin-top: 3rem;
      width: 200px; }
    .login-container .login-box a {
      text-decoration: underline; }
  .login-container .login-links {
    margin-bottom: 4rem;
    color: white; }
    .login-container .login-links button {
      background-color: transparent;
      margin-left: 1rem;
      padding: 0.5rem 1.3rem;
      color: #29BBB3;
      border: 2px solid #29BBB3; }
  .login-container .footer-links {
    text-align: center; }
    .login-container .footer-links ul {
      margin: 0;
      padding: 0; }
      .login-container .footer-links ul li {
        display: inline-block; }
        .login-container .footer-links ul li:before {
          content: '\B7';
          color: white;
          margin: 0 0.5rem; }
        .login-container .footer-links ul li:first-child:before, .login-container .footer-links ul li:last-child:after {
          display: none; }
    .login-container .footer-links a {
      color: white; }

.icon-Triangle.reverse {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg); }

.content-editable {
  overflow-y: scroll;
  padding: 20px 15px;
  max-height: 170px;
  border: 1px solid #DDE0E1;
  -webkit-touch-callout: all;
  /* iOS Safari */
  -webkit-user-select: all;
  /* Safari */
  /* Konqueror HTML */
  -moz-user-select: all;
  /* Firefox */
  -ms-user-select: all;
  /* Internet Explorer/Edge */
  user-select: all;
  /* Chrome and Opera */ }
  .content-editable.plain-text {
    font-family: 'Courier New', Courier, monospace; }
  .content-editable h1 {
    font-size: 130%; }
  .content-editable h2 {
    font-size: 110%; }
  .content-editable h3 {
    font-size: 100%; }
  .content-editable h1,
  .content-editable h2,
  .content-editable h3,
  .content-editable h4 {
    font-weight: bold;
    text-decoration: underline; }
  .content-editable h1,
  .content-editable h2,
  .content-editable h3,
  .content-editable h4,
  .content-editable h5,
  .content-editable h6,
  .content-editable p {
    margin: 0; }

button,
.btn,
input[type="submit"] {
  background-color: #29BBB3;
  border: 0;
  border-radius: 3px;
  color: white;
  font-weight: 700;
  font-size: 0.88rem;
  padding: 0.75rem 1.3rem;
  text-align: center;
  cursor: pointer; }
  button:focus,
  .btn:focus,
  input[type="submit"]:focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  button:hover,
  .btn:hover,
  input[type="submit"]:hover {
    background-color: #668087;
    color: white; }

a.btn {
  text-decoration: none; }

.teal-border-btn {
  background-color: transparent;
  border: 2px solid #29BBB3;
  color: #29BBB3;
  font-size: 0.8rem; }
  .teal-border-btn:hover {
    background-color: transparent;
    color: white;
    border-color: white; }

.teal-solid-btn:hover {
  background-color: white;
  color: #29BBB3; }

#medication-history-modal input[type="radio"],
#medication-history-modal input[type="checkbox"] {
  display: inline;
  width: auto; }

.modal-dialog {
  max-width: 700px;
  top: 50%;
  transform: translateY(-50%) !important;
  -webkit-transform: translateY(-50%) !important;
  -moz-transform: translateY(-50%) !important;
  -ms-transform: translateY(-50%) !important; }
  .modal-dialog .close {
    position: absolute;
    right: 0;
    background-color: transparent !important;
    color: #29BBB3 !important;
    opacity: 1;
    text-shadow: none; }
    .modal-dialog .close span {
      display: inline-block;
      padding: 0.8rem; }
    .modal-dialog .close:hover {
      color: #42515A !important; }

.modal-content {
  box-shadow: 0 0 7px 0 rgba(25, 36, 42, 0.27) !important;
  border-radius: 0 !important;
  border: 0 !important; }
  .modal-content a {
    color: #29BBB3;
    text-decoration: underline; }
    .modal-content a.btn {
      color: white;
      text-decoration: none; }

.modal-header {
  padding: 2rem 2rem 1rem !important;
  border-bottom: 0 !important;
  border-radius: 0 !important; }

.modal-body {
  padding: 0 2rem 1rem !important; }
  .modal-body .small-text-format {
    margin-top: 0;
    margin-bottom: 0.3rem;
    color: #29BBB3;
    font-weight: 700; }
  .modal-body .radio_input {
    margin-bottom: 0.4rem; }
  .modal-body .modal-link {
    color: #91999E;
    font-size: 0.75rem;
    font-weight: 700;
    text-transform: uppercase;
    text-shadow: 0 1px 0 0 #FFFFFF;
    cursor: pointer; }
  .modal-body input,
  .modal-body .select_input,
  .modal-body textarea {
    width: 100%; }
  .modal-body input[type="text"] {
    padding: 0.45rem 0.5rem; }
  .modal-body textarea {
    padding: 0.75rem;
    margin-bottom: 1rem; }
  .modal-body .div-container {
    position: relative; }

.modal-title {
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.2 !important; }

.modal-footer {
  padding: 0 2rem 2rem !important;
  border-top: 0 !important;
  justify-content: flex-start !important; }
  .modal-footer .modal-footer-container {
    display: flex;
    align-items: center;
    width: 100%; }
    .modal-footer .modal-footer-container > :not(:first-child) {
      margin-left: 1.7rem !important; }
    .modal-footer .modal-footer-container button {
      width: 150px; }
    .modal-footer .modal-footer-container .cancel-btn {
      border-bottom: 1px solid #29BBB3;
      cursor: pointer;
      font-size: 0.88rem;
      font-weight: 700;
      text-transform: uppercase; }

.modal-add-pharmacy.modal-dialog {
  max-width: 500px !important; }

#pharmacy-modal hr:last-child {
  margin-top: 2rem; }

#add-pharmacy-modal .half-input .select_input {
  display: inline-block;
  width: 48%; }
  #add-pharmacy-modal .half-input .select_input:first-child {
    margin-right: 4%; }

#add-organization-modal {
  max-width: 454px; }

#export-note-modal {
  max-width: 560px; }
  #export-note-modal .modal-title span {
    margin-left: 0.8rem;
    font-size: 0.88rem;
    font-weight: normal; }
  #export-note-modal .modal-body .row .col:first-child {
    padding-right: 8px; }
  #export-note-modal .modal-body .row .col:last-child {
    padding-left: 8px; }
  #export-note-modal .export-btn {
    padding: 1.5rem 1.2rem !important;
    margin-bottom: 1rem;
    width: 100%;
    text-transform: uppercase; }
    #export-note-modal .export-btn span {
      margin-right: 0.5rem;
      font-size: 1.2rem; }

#save-new-template-modal {
  max-width: 450px; }
  #save-new-template-modal .modal-footer .modal-footer-container {
    border-top: 0;
    padding-top: 0; }

#copy-recovery-modal {
  max-width: 400px; }
  #copy-recovery-modal .tool-tip {
    left: 90%;
    bottom: 60%;
    min-width: 70px; }
  #copy-recovery-modal a {
    text-decoration: none; }
  #copy-recovery-modal .cancel-btn:hover {
    border-color: #42515A; }

#download-pdf-modal {
  max-width: 440px; }
  #download-pdf-modal .tool-tip {
    left: 90%;
    bottom: 60%;
    min-width: 70px; }
  #download-pdf-modal .modal-body button,
  #download-pdf-modal .modal-body .btn {
    width: 100%;
    font-size: 0.77rem !important; }
  #download-pdf-modal .modal-body .row {
    margin-bottom: 1.2rem; }

#output-text-modal {
  max-width: 480px; }
  #output-text-modal .btn-full-width {
    width: 100%; }
  #output-text-modal textarea {
    min-height: 160px; }
  #output-text-modal .rich-text h1, #output-text-modal .rich-text h2, #output-text-modal .rich-text h3, #output-text-modal .rich-text h4, #output-text-modal .rich-text h5, #output-text-modal .rich-text h6,
  #output-text-modal .rich-text p {
    display: inline; }
  #output-text-modal .rich-text ul,
  #output-text-modal .rich-text ol {
    display: inline;
    margin: 0; }

#open-saved {
  max-width: 420px; }

#choose-workspace-modal {
  max-width: 420px; }
  #choose-workspace-modal ul {
    padding: 0 0 0 1rem; }
    #choose-workspace-modal ul li {
      list-style: none;
      color: #29BBB3;
      font-weight: 600;
      cursor: pointer; }
      #choose-workspace-modal ul li:hover {
        color: #19242A; }

.popups {
  display: none;
  position: absolute;
  background-color: #42515A;
  color: white;
  top: 47px;
  left: 50%;
  min-width: 116px;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  border-radius: 3px;
  z-index: 10; }
  .popups ul {
    list-style: none;
    margin: 0;
    padding: 1.2rem 1.1rem 0.8rem; }
    .popups ul li {
      font-size: 0.8rem;
      margin-bottom: 0.4rem;
      cursor: pointer; }
      .popups ul li label {
        font-size: 0.87rem;
        padding-top: 0.18rem; }
      .popups ul li ul {
        padding: 0.1rem 0 0 1.4rem; }
        .popups ul li ul li {
          font-weight: 400; }
  .popups.open {
    display: block; }
  .popups .checkbox-input label:before {
    background-color: #668087; }
  .popups .checkbox-input input[type="checkbox"]:checked + label::before {
    background-color: #668087; }
  .popups.template_filter {
    width: 230px; }
    .popups.template_filter ul li {
      margin-bottom: 0.1rem; }
  .popups:before {
    content: '';
    position: absolute;
    top: -5px;
    left: 45%;
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 6px solid #42515A; }

.popup_label {
  color: #91999E;
  font-size: 0.75rem;
  font-weight: 700;
  text-align: center;
  cursor: pointer; }
  .popup_label .filter-label {
    top: 0;
    font-size: 0.75rem;
    margin-left: 0; }
  .popup_label span {
    position: relative;
    display: inline-block;
    top: 3px;
    font-size: 1.03rem;
    margin-left: 0.2rem; }

.pdf-outline-filter {
  padding: 0.3rem 0.3rem; }

.pdf-settings-outline .popup_label .filter-label {
  display: none; }

@media (min-width: 1400px) {
  .pdf-settings-outline .popup_label .filter-label {
    display: inline-block; } }

.tool-tip {
  display: none;
  position: absolute;
  background-color: #19242A;
  padding: 0.7rem;
  color: white;
  bottom: 130%;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  border-radius: 3px;
  min-width: 170px;
  font-size: 0.7rem;
  z-index: 10;
  text-transform: none; }
  .tool-tip a {
    display: inline-block;
    color: #29BBB3;
    text-decoration: underline; }
    .tool-tip a:hover {
      color: white;
      text-decoration: underline !important; }
  .tool-tip:after {
    content: '';
    position: absolute;
    bottom: -5px;
    left: 45%;
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid #19242A; }
  .tool-tip.open {
    display: block; }

.user-navigation .tool-tip,
.sidebar-navigation .tool-tip,
.bottom-navigation .tool-tip {
  background-color: #29BBB3;
  bottom: 108%;
  padding: 0.5rem 0.2rem;
  min-width: 68px;
  max-width: 68px;
  text-transform: uppercase; }
  .user-navigation .tool-tip:after,
  .sidebar-navigation .tool-tip:after,
  .bottom-navigation .tool-tip:after {
    left: 41%;
    border-top: 6px solid #29BBB3; }

.sidebar-col {
  flex-grow: 0;
  width: 280px; }

.sidebar {
  position: fixed;
  height: 100%;
  width: 280px;
  left: -200px;
  background: #19242A;
  font-size: 1.15rem;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  z-index: 500; }
  .sidebar .user-block {
    margin-bottom: 1.5rem;
    cursor: pointer; }
    .sidebar .user-block .username {
      font-size: 0.88rem;
      font-weight: 700;
      letter-spacing: 0.1px;
      text-transform: uppercase; }
    .sidebar .user-block .name {
      display: inline-block; }
    .sidebar .user-block .icon span {
      position: relative;
      top: 10px;
      font-size: 1.5rem; }
  .sidebar .sidebar_container {
    height: 100%;
    color: #55646D; }
    .sidebar .sidebar_container ul {
      padding-top: 1.4rem;
      padding-bottom: 1.2rem;
      border-top: 1px solid #0D1317;
      border-bottom: 1px solid #0D1317; }
      .sidebar .sidebar_container ul li {
        position: relative; }
      .sidebar .sidebar_container ul .nav-text {
        color: #55646D;
        font-size: 1.15rem; }
        .sidebar .sidebar_container ul .nav-text.active, .sidebar .sidebar_container ul .nav-text:hover {
          color: white;
          font-weight: bold; }
      .sidebar .sidebar_container ul > a {
        font-weight: 700; }
      .sidebar .sidebar_container ul span.menu-icon {
        position: relative;
        margin-right: 0.5rem;
        font-size: 1.5rem;
        top: 4px; }
    .sidebar .sidebar_container span.mini-icon {
      margin-top: .35rem;
      font-size: .5rem; }
    .sidebar .sidebar_container .logo-large {
      background-color: #0D1317;
      padding: 1.5rem 1.8rem;
      margin-bottom: 25px; }
      .sidebar .sidebar_container .logo-large svg {
        max-width: 190px; }
    .sidebar .sidebar_container .logo-small {
      background-color: #0D1317;
      padding: 0.5rem 0;
      margin: 0;
      list-style: none; }
    .sidebar .sidebar_container .nav-container {
      padding: 0rem 1.8rem; }
    .sidebar .sidebar_container .collapse_btn {
      cursor: pointer; }
      .sidebar .sidebar_container .collapse_btn.active, .sidebar .sidebar_container .collapse_btn:hover {
        color: white; }
  .sidebar .upgrade-btn {
    background: #f6b515;
    background: -webkit-linear-gradient(315.64deg, #F6B515 0%, #F69715 100%);
    background: linear-gradient(134.36deg, #F6B515 0%, #F69715 100%);
    border: 0;
    margin-top: 2rem;
    text-transform: uppercase; }
    .sidebar .upgrade-btn a {
      display: inline-block;
      color: white;
      line-height: 1; }
    .sidebar .upgrade-btn .icon-font {
      position: relative;
      top: 1px;
      margin-right: 0.4rem;
      font-size: 1.08rem; }
    .sidebar .upgrade-btn:hover {
      background: white;
      background: -webkit-linear-gradient(315.64deg, white 0%, white 100%);
      background: linear-gradient(134.36deg, white 0%, white 100%); }
      .sidebar .upgrade-btn:hover a {
        color: #F69715; }

.sidebar-navigation {
  list-style: none;
  margin: 0;
  padding: 0; }
  .sidebar-navigation li {
    padding-bottom: 1.3rem; }
    .sidebar-navigation li a {
      color: #55646D;
      font-size: 1.15rem; }
      .sidebar-navigation li a.active, .sidebar-navigation li a:hover {
        color: white;
        font-weight: bold; }
    .sidebar-navigation li:last-child {
      padding-bottom: 0.5rem; }

.bottom-navigation {
  position: absolute;
  bottom: 1.7rem;
  padding-bottom: 0 !important;
  border: 0 !important;
  list-style: none; }
  .bottom-navigation li:last-child {
    padding-bottom: 0; }
  .bottom-navigation .reverse {
    display: inline-block;
    -webkit-transform: rotate(-180deg);
            transform: rotate(-180deg); }

.collapsed {
  width: 280px !important;
  left: -200px !important; }
  .collapsed .collapsed_menu {
    opacity: 1; }

.collapsed_menu button,
.bottom-navigation button {
  background-color: transparent !important;
  padding: 0;
  color: #55646D !important;
  padding: 0 !important;
  font-weight: 400 !important;
  font-size: inherit !important; }
  .collapsed_menu button:hover,
  .bottom-navigation button:hover {
    color: white !important;
    font-weight: 700 !important; }
  .collapsed_menu button:focus,
  .bottom-navigation button:focus {
    box-shadow: none;
    /*outline-style: dashed;
      outline-color: $blue-grey;
      outline-width: 1px;*/ }

.collapsed_menu {
  position: absolute;
  background: #19242A;
  width: 80px;
  height: 100%;
  top: 0;
  left: 200px;
  text-align: center;
  opacity: 1; }
  .collapsed_menu svg {
    max-width: 40px; }
  .collapsed_menu ul span {
    font-size: 1.9rem;
    margin-right: 0; }
  .collapsed_menu ul .nav-text {
    display: none; }
  .collapsed_menu .user-navigation {
    padding: 0.5rem 0 0.3rem !important;
    margin: 0; }
  .collapsed_menu .user-block .pharmacy-name,
  .collapsed_menu .user-block .username {
    display: none; }
  .collapsed_menu .user-block .icon {
    flex: none;
    max-width: 100%;
    text-align: center; }
  .collapsed_menu .sidebar-navigation {
    border-top: 2px solid #0D1317;
    border-bottom: 2px solid #0D1317; }
  .collapsed_menu .upgrade-nav {
    border: 0 !important; }
  .collapsed_menu .upgrade-btn {
    background: transparent;
    margin-top: 0; }
    .collapsed_menu .upgrade-btn .icon-font {
      font-size: 1.9rem; }
    .collapsed_menu .upgrade-btn span {
      color: #F69715; }
    .collapsed_menu .upgrade-btn:hover {
      background: transparent; }
      .collapsed_menu .upgrade-btn:hover span {
        color: white; }
  .collapsed_menu .bottom-navigation {
    padding: 0;
    margin: 0 auto;
    width: 100%; }
    .collapsed_menu .bottom-navigation li {
      width: 100%;
      margin-bottom: 1rem; }
      .collapsed_menu .bottom-navigation li:last-child {
        margin-bottom: 0; }

.sidebar-open .sidebar {
  width: 250px;
  left: 0; }

.sidebar-open .collapsed_menu {
  opacity: 0; }

html {
  font-size: 14px; }

body {
  font-family: 'Catamaran', sans-serif;
  color: #42515A; }

a {
  color: #29BBB3;
  -webkit-transition: 0.2s;
  transition: 0.2s; }

a:hover {
  color: #19242A;
  text-decoration: none; }

body,
html,
#root,
.App {
  width: 100%; }

body,
html,
#root,
.App,
.App > .container-fluid,
.App > .container-fluid > Row,
.main_content {
  height: 100%; }

.App {
  background-color: #EDEDED; }
  .App.login-page {
    background-color: #19242A; }
  .App.login-page,
  .App.login-page > .container-fluid,
  .App.login-page > .container-fluid > Row,
  .App.login-page .main_content {
    height: 100%; }

.main-header {
  font-size: 1.5rem;
  font-weight: 600; }

.small-text-format {
  margin-top: 18px;
  font-size: 10px;
  font-weight: 500;
  text-transform: uppercase; }

.small-header {
  color: #29BBB3;
  font-weight: 900; }

.small-text {
  font-size: 10px; }

.med-text {
  font-size: 0.88rem; }

.main_content {
  background-color: #EDEDED;
  margin-left: 80px;
  width: 100%;
  height: 100%; }

.sidebar-open .sidebar {
  width: 250px;
  left: 0; }

.sidebar-open .collapsed_menu {
  opacity: 0; }

@media (min-width: 1200px) {
  .sidebar-open .main_content {
    margin-left: 250px; } }

