@import '../../scss/variables';
@import '../../scss/breakpoints';
@import '../../scss/colours';
@import '../../scss/mixins';

.page_header {
  position: fixed;
  background: $grey;
  color: white;
  padding: 2rem;
  margin-bottom: 1.5rem;
  z-index: 100;

  .back_btn {
    @include vertical-align-middle;
    display: inline-block;
    width: 10%;
    padding: 0.8rem 0;
    font-size: 0.7rem;
    position: relative;
    vertical-align: top;
  }

  .page_title {
    display: inline-block;
    width: 85%;
    padding-left: 0.7rem;
    font-size: 1.32rem;
    margin-right: 0.7rem;
    line-height: 1.0;
    @include vertical-align-middle;
  }

  .template_name {
    margin-right: 0.5rem;
  }

  .pharmacy_name {
    font-size: 1.1rem;
    color: $blue-grey;
  }

  .template_name,
  .pharmacy_name {
    display: inline;
  }

  .header_right {
    text-align: right;

    button {
      margin-left: 0.5rem;
    }
  }

  .header_menu {
    display: inline-block;

    span {
      position: relative;
      top: 0.3rem;
      padding: 0.4rem;
      cursor: pointer;
    }
  }

  .status {
    display: none;
    color: #42515A;
    font-size: 0.8rem;
    margin-right: 1rem;
  }

  .ui_btns {
    display: none;

    button { padding: 0.75rem 1rem; }
  }

  .text_link {
    margin-right: 1rem;
    color: $teal;
    font-size: .78rem;
    text-transform: uppercase;
    font-weight: bold;
    border-bottom: 1px solid $teal;
    cursor: pointer;
  }
}

@media(min-width:$lg) {
  .header_right {
    .header_menu { display: none; }
    .ui_btns {
      display: block;

      div:not(.status) { display: inline-block; }
    }
  }
}

@media(min-width:$xl) {
  .page_header {
    .page_title {
      font-size: 1.5rem;
    }
  
    .pharmacy_name {
      font-size: 1.25rem;
    }

    .header_right {
      .header_menu { display: none; }
      .ui_btns {
        display: block;

        button { padding: 0.75rem 0.5rem; }
      }
    }
  }
}

@media(min-width:1400px) {
  .page_header {
    .header_right {
      .ui_btns {
        button { padding: 0.75rem 1.2rem; }
      }
    }
  }
}