.tool-tip {
  display: none;
  position: absolute;
  background-color: #19242A;
  padding: 0.7rem;
  color: white;
  bottom: 130%;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 3px;
  min-width: 170px;
  font-size: 0.7rem;
  z-index: 10;
  text-transform: none;

  a {
    display: inline-block;
    color: $teal;
    text-decoration: underline;

    &:hover {
      color: white;
      text-decoration: underline!important;
    }
  }

  &:after {
    content: '';
    position: absolute;
    bottom: -5px;
    left: 45%;
    width: 0; 
    height: 0; 
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid #19242A;
  }

  &.open {
    display: block;
  }
}

.user-navigation,
.sidebar-navigation,
.bottom-navigation {
  .tool-tip {
    background-color: $teal;
    bottom: 108%;
    padding: 0.5rem 0.2rem;
    min-width: 68px;
    max-width: 68px;
    text-transform: uppercase;

    &:after {
      left: 41%;
      border-top: 6px solid $teal;
    }
  }
}