@import '../../../../scss/breakpoints';
@import '../../../../scss/colours';
@import '../../../../scss/mixins';

.template_item {
  //padding: 25px 0 35px;
  padding: 30px 25px;
  border-bottom: 1px solid $border-grey;
  align-items: center;

  .name {
    a {
      color: $header-grey;
      font-size: 1.2rem;
      font-weight: 600;
      line-height: 1.3;
    }
  }

  .owner {
    display: block;
    color: #91999E;
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.3;
  }

  .format {
    color: #91999E;
    margin-top: 18px;
    font-size: 10px;
    font-weight: 500;
    text-transform: uppercase;

    .icon_font {
      display: inline-block;
      font-size: 1.45rem;
      width: 15%;
      margin-right: 0.2rem;
      vertical-align: top;
    }

    div.text_container {
      display: inline-block;
      width: 80%;
      line-height: 1.1;
      cursor: pointer;

      span {
        position: relative;
        top: 1px;
        display: inline;
        font-size: 0.7rem;
        margin-left: 0.2rem;
        color: $teal;
      }

      span.text {
        display: inline;
        color: #91999E;
        margin-left: 0;
        top: 0;
        font-size: 0.7rem;
        font-weight: 700;
        line-height: 0.7;
      }
    }
  }

  .name {
    flex: 0 0 33%;
    max-width: 33%;
  }

  .owner {
    flex: 0 0 100%;
    max-width: 100%;
  }
  
  .format {
    flex: 0 0 30%;
    max-width: 30%;
  }

  .links_container {
    position: relative;
    flex: 0 0 25%;
    max-width: 25%;
    right: 0;
  }

  .name + .owner {
    flex: 0 0 23%;
    max-width: 23%;
  }

  .owner + .format {
    flex: 0 0 23%;
    max-width: 23%;
  }
  
  .owner + .format + .links_container {
    flex: 0 0 21%;
    max-width: 21%;
  }

  button {
    display: inline-block;
  }

  .btn {
    //background-color: $teal;
    margin-top: 7px;
    
  }

  a { 
    display: inline-block;

    &:hover {
      text-decoration: none;
    }
  }

  .create_note_btn {
    padding: 0.75rem 1rem;
    width: 100%;
  }

  .edit_btn {
    background-color: white;
    border: 2px solid #BFC6CB;
    color: #AAB1B5;
  }
}

@media(min-width:1024px) {
  .template_item {
    .format { margin-top: 0; }
  }

  .create_note_btn {
    padding: 0.75rem 1.5rem;
  }
}

@media(min-width:1336px) {
  .template_item {
    .format {
      display: block;
      margin-top: 0;
    }

    .name {
      font-size: 1.5rem;
    }
  }
}

@media(min-width:$xl) {
  .template_item {
    .name {
      flex: 0 0 45%;
      max-width: 45%;
    }

    .format,
    .links_container {
      flex: 0 0 25%;
      max-width: 25%;
    }

    .owner + .format,
    .owner + .format + .links_container {
      flex: 0 0 16%;
      max-width: 16%;
    }
  }
}

@media(min-width:1400px) {
  .template_item {
    .format,
    .links_container {
      flex: 0 0 25%;
      max-width: 25%;
    }
  }
}