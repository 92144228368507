@import '../../scss/colours';
@import '../../scss/mixins';

.mobile_view {
  position: fixed;
  background-color: $grey;
  color: white;
  width: 100%;
  height: 100%;
  left: 0;
  font-size: 0.88rem;
  text-align: center;

  .mobile_content {
    @include vertical-align-middle;
    position: relative;

    .logo {
      margin-bottom: 2rem;
    }

    .message {
      padding: 0 3rem;
      margin-bottom: 5rem;
      font-weight: 600;
    }

    .pharmacy_name {
      margin-top: 0.2rem;
      font-size: 1rem;
      font-weight: 600;
    }

    .username {
      text-transform: uppercase;
      margin-bottom: 2rem;
      font-size: 0.9rem;
    }

    .button_link {
      a {
        padding: 0.5rem 1.2rem!important;
      }
    }
  }

  .help_link {
    position: absolute;
    text-align: center;
    width: 100%;
    bottom: 3rem;
    font-size: 1rem;
    font-weight: 600;

    a {
      color: $blue-grey;
    }

    span {
      display: inline-block;
      padding-bottom: 0.1rem;
      margin-right: 0.4rem;
      font-size: 1.2rem;
      vertical-align: middle
    }
  }
}