@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?u6fifz');
  src:  url('fonts/icomoon.eot?u6fifz#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?u6fifz') format('truetype'),
    url('fonts/icomoon.woff?u6fifz') format('woff'),
    url('fonts/icomoon.svg?u6fifz#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-opennew:before {
  content: "\e926";
  color: #91999e;
}
.icon-Circle_Checkmark .path1:before {
  content: "\e90a";
  color: rgb(41, 187, 179);
}
.icon-Circle_Checkmark .path2:before {
  content: "\e923";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Circle_X .path1:before {
  content: "\e924";
  color: rgb(188, 32, 37);
}
.icon-Circle_X .path2:before {
  content: "\e925";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Back-Arrow:before {
  content: "\e909";
}
.icon-Finish_Save:before {
  content: "\e913";
}
.icon-Kroll:before {
  content: "\e914";
}
.icon-pdf:before {
  content: "\e915";
}
.icon-text:before {
  content: "\e916";
}
.icon-CustomForm:before {
  content: "\e917";
}
.icon-Dashboard:before {
  content: "\e918";
}
.icon-Edit:before {
  content: "\e919";
}
.icon-Expand:before {
  content: "\e91a";
}
.icon-Help:before {
  content: "\e91b";
}
.icon-NewNote:before {
  content: "\e91c";
}
.icon-OpenSaved:before {
  content: "\e91d";
}
.icon-Profile:before {
  content: "\e91e";
}
.icon-Settings:before {
  content: "\e91f";
}
.icon-Upgrade_1:before {
  content: "\e920";
}
.icon-Upgrade_2:before {
  content: "\e921";
}
.icon-Upgrade_3:before {
  content: "\e922";
}
.icon-Arrow:before {
  content: "\e900";
}
.icon-Arrow_Line:before {
  content: "\e901";
}
.icon-Checkmark:before {
  content: "\e902";
}
.icon-Delete:before {
  content: "\e903";
}
.icon-DotMenu:before {
  content: "\e904";
}
.icon-DragIcon:before {
  content: "\e905";
}
.icon-DropDown:before {
  content: "\e906";
}
.icon-Duplicate:before {
  content: "\e907";
}
.icon-Filter:before {
  content: "\e908";
}
.icon-Info:before {
  content: "\e90b";
}
.icon-Plus:before {
  content: "\e90c";
}
.icon-Primary:before {
  content: "\e90d";
}
.icon-Search:before {
  content: "\e90e";
}
.icon-Sort:before {
  content: "\e90f";
}
.icon-Triangle:before {
  content: "\e910";
}
.icon-X_Bigger:before {
  content: "\e911";
}
.icon-X_CloseForm:before {
  content: "\e912";
}
