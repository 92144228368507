@import '../../../scss/breakpoints';
@import '../../../scss/colours';

.template_section {
  padding-top: 140px;
}

.section_name {
  font-size: 1.5rem;
  font-weight: 600;
}

.basic_information {
  background: white;
  padding: 25px;
  margin-bottom: 25px;

  input {
    width: 100%;
  }
}

.template_content {
  background: white;
  padding: 25px;
  margin-bottom: 25px;
}

.pdf_content {
  background: white;
  padding: 25px;
}