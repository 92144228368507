@import '../../../scss/breakpoints';
@import '../../../scss/colours';

.settings_container {
  padding: 1.3rem;
}

.pdf_settings {
  max-height: 0;
  transition: 0.5s;
  overflow: hidden;

  &.open {
    max-height: 700px;
    overflow: visible;
  }

  .search_box {
    background-color: $light-white;
    border: 0;
    border-radius: 2px;
    font-size: 1rem;
    padding: 7px 14px;
    padding-left: 20px;
    width: 100%;
    margin-bottom: 0.8rem;
  }
  
  .setting_search {
    position: relative;
  
    span {
      position: absolute;
      top: 0.6rem;
      left: 0.5rem;
      font-size: 1.1rem;
      color: #91999E;
    }
  
    input {
      padding-left: 2rem;
      width: 100%;
    }

    ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
      font-size: 0.9rem;
    }
    
    :-ms-input-placeholder { /* Internet Explorer 10-11 */
      font-size: 0.9rem;
    }
    
    ::-ms-input-placeholder { /* Microsoft Edge */
      font-size: 0.9rem;
    }
  }

  .setting_meta {
    display: flex;
  }

  .setting_filters {
    flex: 0 0 80%;
    max-width: 80%;
  }

  .setting_results {
    flex: 0 0 20%;
    max-width: 20%;
    font-size: 0.88rem;
    font-weight: 600;
    margin: 0.5rem 0;
    text-align: right;
  }

  .setting_item {
    line-height: 1.5;
    padding: 0.8rem 0 0.5rem;
    border-bottom: 1px solid $border-grey;
  }

  .setting_header,
  .setting_toggle {
    min-height: 40px;
    //padding-top: 0;
    //padding-bottom: 0.9rem;
  }

  .setting_header {
    padding-right: 0;
    font-weight: 600;
    font-size: 1.05rem;
    line-height: 1.2;

    .category {
      font-weight: 400;
      font-size: 0.9rem;
    }
  }

  .setting_toggle {
    color: $teal;
    text-transform: uppercase;
    text-decoration: underline;
    font-size: 0.68rem;
    font-weight: 800;
    cursor: pointer;
    align-items: center;
    display: flex;
    text-align: right;
  }
}

@media ( min-width: $lg ) {
  .settings_container {
    padding: 2rem;
  }
}

@media ( min-width: $xl ) {
  .setting_toggle {
    justify-content: right;
  }
}