@import '../../scss/breakpoints';
@import '../../scss/colours';

.dashboard {
  background-color: white;

  header {
    color: $header-grey;
    font-size: 2rem;
    font-weight: 600;
    padding: 0 1rem;
  }

  .dash_row {
    border-bottom: 1px solid #DDE0E1;
    padding: 30px 25px;

    &.filter_row,
    &.templates_row {
      border-bottom: 0;
    }
  }
}

.dashboard_container {
  padding-top: 0.90rem;
}

.dashboard_templates {
  display: none;
}

.dashboard_templates_open {
  display: block;
}

.dashboard_widget {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-bottom: 2rem;
  flex: 0 0 50%;
  max-width: 50%;

  input[type=text] {
    width: 100%;
    padding: 0.45rem 0.5rem;
  }
}

.widget_container {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
}

.end_results {
  position: relative;
  padding-bottom: 1.8rem;
  padding-top: 1rem;
  color: #91999E;
  font-size: 0.6rem;
  text-align: center;
  text-transform: uppercase;
  overflow: hidden;

  .end_results_text {
    position: absolute;
    span {
      width: 10%;
    }

    &:before {
      content: '';
      position: relative;
      display: inline-block;
      top: -2px;
      margin-right: 1rem;
      border: 1px dashed #91999E;
      width: 41%;
    }

    &:after {
      content: '';
      position: relative;
      display: inline-block;
      top: -2px;
      margin-left: 1rem;
      border: 1px dashed #91999E;
      width: 41%;
    }
  }
}

@media(min-width:$xl) {
  .end_results {
    .end_results_text {
      span { width: 10%; }
      &:before { width: 44%; }
      &:after { width: 44%; }
    }
  }
}

@media(min-width:$xl) {
  .dashboard_templates {
    display: block;
  }

  .dashboard_widget {
    flex: 0 0 100%;
    max-width: 100%;
    padding-right: 0;
  }

  .widget_container {
    position: fixed!important;
    padding-right: 15px;
  }
}