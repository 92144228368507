@import '../../../../scss/colours';

.block {
  margin-bottom: 1rem;

  .name {
    color: #4A4A4A;
    font-size: 1.08rem;
    font-weight: 600;
    margin-bottom: 0.8rem;
  }

  .tool_tip_container {
    position: relative;
    display: inline-block;
    margin-left: 0.2rem;
    
    span {
      color: $teal;
      font-size: 0.9rem;
      cursor: pointer;
    }
  }

  &.hide_block {
    display: none;
  }
}

@media ( min-width: 564px ) {
  .grid_h {
    .checkbox_field,
    .radio_field,
    .input_group_field {
      display: inline-block;
      width: 50%;
    }
  }
}

@media ( min-width: 1024px ) {
  .grid_h {
    .checkbox_field,
    .radio_field {
      display: inline-block;
      //width: 33.333%;
    }
  }
}

@media ( min-width: 1336px ) {
  .grid_h {
    .checkbox_field,
    .radio_field,
    .input_group_field {
      display: inline-block;
      width: 33.333%;
    }
  }
}

@media ( min-width: 1500px ) {
  .grid_h {
    .checkbox_field,
    .radio_field,
    .input_group_field {
      display: inline-block;
      width: 25%;
    }
  }
}