select,
.select_input {
  border: 1px solid $border-grey;
  border-radius: 2px;
  font-size: 0.88rem;
  width: 100%;

  div {
    border: 0;
  }

  .css-yk16xz-control,
  .css-1pahdxg-control {
    max-height: 30px;
    min-height: 30px;
  }

  .css-1wa3eu0-placeholder {
    color: $main-grey;
  }

  svg {
    fill: $teal;
  }

  .css-tlfecz-indicatorContainer,
  .css-1gtu0rj-indicatorContainer {
    padding: 3px 5px;
  }

  .css-1okebmr-indicatorSeparator { display: none; }

  .css-yk16xz-control,
  .css-1pahdxg-control {
    border-color: $teal;
    outline-color: $teal;

    &:active,
    &:focus {
      border-color: $teal;
    }

    &:hover {
      border-color: $teal;
      box-shadow: 0 0 0 1px $teal;
    }
  }
}

.select-input {
  label { width: 15%; }

  label + select {
    width: 80%;
  }
}