@import '../../../../scss/breakpoints';
@import '../../../../scss/colours';

.submenu {
  position: absolute;
  display: none;
  top: 1.1rem;
  left: 2rem;
  background: white;
  width: 190px;
  text-align: left;
  z-index: 50;

  & > ul {
    list-style: none;
    margin: 0;
    padding: 0!important;
    border: 0!important;
    border-bottom: 1px solid #DDE0E1!important;

    & > li {
      position: relative;
      color: $blue-grey;
      cursor: pointer;
      font-size: 0.8rem;
      padding: 1rem 1.2rem;
      font-weight: 600;

      div { position: relative; }

      a { 
        position: relative;
        display: block;
        color: $blue-grey;
        transition-duration: 0s;
      }

      span {
        position: absolute;
        margin-top: 2px;
        right: 0;
        font-size: .8rem;
      }

      ul {
        li { list-style: none; }
        ul { padding: 0; }
      }
    }

    & > li:hover {
      background-color: $teal;
      //color: white;

      & > div > span,
      & > a > span:before,
      & > div,
      & > a { color: white; }

      ul li a  { color: $blue-grey!important; }
    }
  }

  & > ul:last-child {
    border-bottom: 0!important;
  }
  

  &.open { display: block; }
}

@media(min-width:$xl) {
  .submenu {
    left: 1rem;
  }
}