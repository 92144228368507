@import '../../../../scss/colours';

.hide_input {
  display: none;
}



.text_multiline {
  width: 100%;

  textarea {
    width: 100%;
  }
}

/*.input_group {
  //margin-bottom: 0.5rem;
  vertical-align: top;

  .group_item {
    display: inline-block;
    vertical-align: middle;
  }

  .group_item:last-child {
    //width: 50%;
    margin-right: 0;
  }

  .group_item:nth-child(2),
  .group_item:nth-child(3) {
    margin-left: 0.5rem;
  }
}*/

