.text-input {
  width: 100%;
  //margin-bottom: 0.5rem;

  label {
    //margin-right: 1.5rem;
  }
  
  input[type="text"] {
    background-color: #FFFBF2;
    padding: 0.3rem 0.5rem 0;
    //border: 1px solid #DDE0E1;

    border: 0;
    border-bottom: 1px solid $main-grey;

    &.has-label-after {
      width: 60%!important;
    }
  }

  .label-before,
  .label-after {
    display: inline-block;
  }

  .label-after { 
    margin-left: 0.3rem;
  }
}

input[type="text"] {
  padding: 0.3rem 0.5rem;
}