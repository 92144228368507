:global(button),
:global(.btn),
:global(input[type="submit"]) {
  background-color: $teal;
  border: 0;
  border-radius: 3px;
  color: white;
  font-weight: 700;
  font-size: 0.88rem;
  padding: 0.75rem 1.3rem;
  text-align: center;
  cursor: pointer;
  //height: 45px!important;

  &:focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }

  &:hover {
    background-color: #668087;
    color: white;
  }
}

a.btn { text-decoration: none; }

.teal-border-btn {
  background-color: transparent;
  border: 2px solid $teal;
  color: $teal;
  font-size: 0.8rem;

  &:hover {
    background-color: transparent;
    color: white;
    border-color: white;
  }
}

.teal-solid-btn {
  &:hover {
    background-color: white;
    color: $teal;
  }
}