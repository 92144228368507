@import '../../../../scss/breakpoints';
@import '../../../../scss/colours';

.change_workspace {
  position: absolute!important;
  background: white;
  left: 99%;
  top: 0;
  width: 250px;
  padding: 0;
  padding-top: 0!important;
  padding-bottom: 0!important;
  box-shadow: 0 0 10px 0 rgba(black, .33);
  border: 0!important;
  z-index: 5;

  & > li {
    padding: 0!important;
  }

  .manage_workspaces {
    padding: 0.8rem 1.2rem!important;
    border-top: 1px solid $border-grey;

    &:hover {
      background-color: $teal;
      color: white;
      
      a,
      span:before { color: white!important; }
    }
  }
}

.workspace_icon {
  font-size: 1rem!important;
}

.selected_div {
  display: inline-block;
  width: 12%;
}

.label {
  display: inline-block;
  width: 88%;
  cursor: pointer;
}

.small_header {
  padding: 0.8rem;
  font-size: 10px;
  color: $teal;
  text-transform: uppercase;
}

.pharmacy_list {
  padding-top: 0!important;
  padding-bottom: 1.2rem!important;
  border: 0!important;
}

.pharmacy_no_listing {
  padding: 0.8rem 1.2rem;
  cursor: auto!important;

  &:hover { color: $grey; }
}

.pharmacy_listing {
  padding: 0.8rem 1.2rem;
  cursor: pointer;

  span {
    position: relative!important;
  }

  &:hover {
    background-color: $teal;
    color: white;
  
    span { color: white; }
  }
}

#workspace_menu {
  display: none;
}