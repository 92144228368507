@import '../../../scss/breakpoints';
@import '../../../scss/colours';

.row_item {
  align-items: center;
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid $border-grey;
}

.header_check,
.check {
  flex: 0 0 3%;
  max-width: 3%;
  padding-right: 0;
}

.header_templates,
.name {
  flex: 0 0 20%;
  max-width: 20%;
  justify-content: center;
  align-content: center;
}

.header_owner,
.owner {
  flex: 0 0 15%;
  max-width: 15%;
}

.header_creator,
.creator {
  flex: 0 0 10%;
  max-width: 10%;
}

.header_date,
.date {
  flex: 0 0 13.5%;
  max-width: 13.5%;
}

.header_btn {
  flex: 0 0 10%;
  max-width: 10%;
  padding-right: 0;
}

/*.check,
.name,
.owner,
.creator,
.date,
.link {
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid $border-grey;
}*/

.link {
  flex: 0 0 11%;
  max-width: 11%;
  padding-right: 0;
}

.edit_template {
  padding-left: 0;
}

.name {
  a { color: $main-grey; }
}

.name,
.owner {
  font-size: 17px;
}

.create_note_link {
  font-weight: bold;
  text-transform: uppercase;
  text-decoration: underline;
}