@import '../../../scss/colours';

.outline {
  list-style: none;
  margin: 0;
  padding: 0;
  max-height: 0;
  overflow: hidden;
  transition: 0.5s;

  li {
    list-style: none;

    span {
      display: block;
      padding: 0.8rem 1.3rem;
      font-size: 1rem;
      cursor: pointer;
    }

    ul {
      margin: 0;
      padding: 0;

      li {
        span { padding-left: 1.2rem; }
      }
    }
  }

  & > li > span { 
    font-weight: 600;
  }

  & > li > ul > li > span:hover,
  & > li > span:hover,
  & li.selected span {
    background-color: $teal;
    color: white;
  }

  &.open {
    max-height: 700px;
  }
}

@media ( min-width: 1024px ) {
  .outline {
    li {
      span {
        font-size: 1.07rem;
      }

      ul li span { padding-left: 2.7rem; }
    }
  }
}