@charset "UTF-8";
@import url(./images/icons/style.css);
@import url("https://fonts.googleapis.com/css?family=Catamaran:400,600,700,800&display=swap");
.checkbox-input {
  position: relative; }
  .checkbox-input input[type="checkbox"] {
    position: absolute;
    opacity: 0;
    width: 16px;
    height: 16px;
    top: 3px;
    z-index: 3; }
  .checkbox-input label {
    position: relative;
    display: inline-block;
    padding-left: 28px;
    vertical-align: top; }
    .checkbox-input label::before {
      content: '';
      background-color: #E5E7E8;
      display: inline-block;
      height: 16px;
      width: 16px;
      top: 3px;
      left: 0; }
    .checkbox-input label::after {
      content: '\e902';
      display: inline-block;
      font-family: 'icomoon' !important;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      font-size: 0.84rem;
      line-height: 1;
      top: 5px;
      left: 2px;
      color: white;
      height: 16px;
      width: 16px; }
  .checkbox-input label::before,
  .checkbox-input label::after {
    position: absolute; }
  .checkbox-input input[type="checkbox"] + label::after {
    content: none; }
  .checkbox-input input[type="checkbox"]:checked + label::after {
    content: "" !important; }
  .checkbox-input input[type="checkbox"]:checked + label::before {
    background-color: #29BBB3; }

.custom-templates .checkbox-input input[type="checkbox"]:checked + label::before {
  background-color: #F6BE15; }

.date-input label {
  display: inline-block; }

.date-input .react-datepicker-wrapper {
  width: 50%; }

.date-input .react-datepicker-popper {
  z-index: 50; }

.date-input input[type="text"] {
  padding: 0.3rem 0.5rem 0;
  border: 0;
  border-bottom: 1px solid #42515A; }

.input-group {
  align-items: center; }

.group-item {
  width: 100%; }

/*.group-item-text {
  display: block;
  width: 100%;
}

.group-item-input-text {
  .text-input { margin-bottom: 0; }
  .no-label-before.no-label-after { width: 100%; }
}

.group-item-checkbox {
  //width: 8%;

  .checkbox-input label.empty-label {
    padding-left: 20px;
  }
}

.group-item-checkbox + .group-item-select {
  width: 33%;
}

.group-item-select:last-child {
  width: 80%;
}

.group-item-checkbox.has-label + .group-item-select:last-child {
  width: 70%;
}

/*.group-item-checkbox.has-label + .group-item-input-text:last-child {
  width: 40%;
}*/
/*.group-item-radio + .group-item-date:last-child {
  width: 50%;
}*/
/*.group-item-checkbox + .group-item-input-text,
.group-item-radio + .group-item-input-text,
.group-item-radio + .group-item-select {
  margin-left: 0.5rem;
}

.group-item-date {
  width: 100%;

  .react-datepicker-wrapper {
    margin-left: 0.5rem;
    width: 30%;
  }
}*/
.input-radio-input-text input[type=radio]:checked ~ input[type=text] {
  border-color: #29BBB3; }

.input-checkbox-input-text input[type=checkbox]:checked + input[type=text] {
  border-color: #29BBB3; }

.input-checkbox-input-select .checkbox-input,
.input-checkbox-input-select .select-input {
  display: inline-block; }

.input-checkbox-input-select .checkbox-input {
  width: 9%; }

.input-checkbox-input-select .select-input {
  width: 79%; }

.grid_v .group-item {
  width: 100%; }

.grid_v .input-checkbox-input-text .text-input,
.grid_v .input-radio-input-text .text-input {
  display: inline-block;
  padding-left: .5rem;
  width: 60%; }
  .grid_v .input-checkbox-input-text .text-input input[type="text"],
  .grid_v .input-radio-input-text .text-input input[type="text"] {
    width: 100%; }

.grid_v .input-checkbox-input-text .checkbox-input,
.grid_v .input-checkbox-input-date .checkbox-input {
  display: inline-block; }

.grid_v .input-radio-input-text .radio-input,
.grid_v .input-radio-input-date .radio-input {
  display: inline-block; }

.grid_v .input-checkbox-input-date .date-input,
.grid_v .input-radio-input-date .date-input {
  display: inline-block;
  margin-left: .5rem; }

.grid_v .input-radio-input-text-input-date .radio-input {
  display: inline-block; }

.grid_v .input-radio-input-text-input-date .text-input {
  display: inline-block;
  padding-left: .5rem;
  width: 50%; }
  .grid_v .input-radio-input-text-input-date .text-input input[type="text"] {
    width: 100%; }

.grid_v .input-radio-input-text-input-date .date-input {
  display: inline-block;
  margin-left: 1.4rem; }

.grid_v .input-date-input-select .date-input {
  display: inline-block;
  margin-bottom: .5rem; }

.grid_h .input-checkbox-input-text .group-item {
  width: 100%; }

.grid_h .input-checkbox-input-text .has-label,
.grid_h .input-checkbox-input-text .checkbox-input {
  width: 100%; }

.grid_h .input-checkbox-input-text .text-input {
  width: 90%;
  padding-left: 28px; }
  .grid_h .input-checkbox-input-text .text-input input[type="text"] {
    width: 100%; }

.grid_h .input-radio-input-text .group-item {
  width: 100%; }

.grid_h .input-radio-input-text .has-label,
.grid_h .input-radio-input-text .radio-input {
  width: 100%; }

.grid_h .input-radio-input-text .text-input {
  width: 90%;
  padding-left: 28px; }
  .grid_h .input-radio-input-text .text-input input[type="text"] {
    width: 100%; }

.radio-input .radio_option {
  position: relative; }
  .radio-input .radio_option input[type="radio"] {
    position: absolute;
    opacity: 0;
    width: 15px;
    height: 15px;
    top: 3px;
    width: 100px;
    z-index: 3; }
  .radio-input .radio_option label {
    position: relative;
    display: inline-block;
    padding-left: 22px;
    margin-bottom: 0.2rem; }
    .radio-input .radio_option label::before {
      content: '';
      background-color: #E5E7E8;
      display: inline-block;
      height: 16px;
      width: 16px;
      top: 3px;
      left: 0;
      border-radius: 8px; }
    .radio-input .radio_option label::after {
      content: '';
      display: inline-block;
      background-color: #29BBB3;
      line-height: 1;
      top: 6px;
      left: 0.185rem;
      color: white;
      width: 10px;
      height: 10px;
      border-radius: 5px; }
  .radio-input .radio_option label::before,
  .radio-input .radio_option label::after {
    position: absolute; }
  .radio-input .radio_option input[type="radio"] + label::after {
    background-color: transparent; }
  .radio-input .radio_option input[type="radio"]:checked + label::after {
    background-color: #29BBB3; }
  .radio-input .radio_option input[type="checkbox"]:checked + label::before {
    background-color: #29BBB3; }

select,
.select_input {
  border: 1px solid #DDE0E1;
  border-radius: 2px;
  font-size: 0.88rem;
  width: 100%; }
  select div,
  .select_input div {
    border: 0; }
  select .css-yk16xz-control,
  select .css-1pahdxg-control,
  .select_input .css-yk16xz-control,
  .select_input .css-1pahdxg-control {
    max-height: 30px;
    min-height: 30px; }
  select .css-1wa3eu0-placeholder,
  .select_input .css-1wa3eu0-placeholder {
    color: #42515A; }
  select svg,
  .select_input svg {
    fill: #29BBB3; }
  select .css-tlfecz-indicatorContainer,
  select .css-1gtu0rj-indicatorContainer,
  .select_input .css-tlfecz-indicatorContainer,
  .select_input .css-1gtu0rj-indicatorContainer {
    padding: 3px 5px; }
  select .css-1okebmr-indicatorSeparator,
  .select_input .css-1okebmr-indicatorSeparator {
    display: none; }
  select .css-yk16xz-control,
  select .css-1pahdxg-control,
  .select_input .css-yk16xz-control,
  .select_input .css-1pahdxg-control {
    border-color: #29BBB3;
    outline-color: #29BBB3; }
    select .css-yk16xz-control:active, select .css-yk16xz-control:focus,
    select .css-1pahdxg-control:active,
    select .css-1pahdxg-control:focus,
    .select_input .css-yk16xz-control:active,
    .select_input .css-yk16xz-control:focus,
    .select_input .css-1pahdxg-control:active,
    .select_input .css-1pahdxg-control:focus {
      border-color: #29BBB3; }
    select .css-yk16xz-control:hover,
    select .css-1pahdxg-control:hover,
    .select_input .css-yk16xz-control:hover,
    .select_input .css-1pahdxg-control:hover {
      border-color: #29BBB3;
      box-shadow: 0 0 0 1px #29BBB3; }

.select-input label {
  width: 15%; }

.select-input label + select {
  width: 80%; }

input[type=password] {
  padding: .4rem .5rem;
  border: 1px solid #DDE0E1;
  border-radius: 2px; }

.label-after {
  margin-left: 0.5rem; }

textarea {
  border: 1px solid #DDE0E1; }

.text-input {
  width: 100%; }
  .text-input input[type="text"] {
    background-color: #FFFBF2;
    padding: 0.3rem 0.5rem 0;
    border: 0;
    border-bottom: 1px solid #42515A; }
    .text-input input[type="text"].has-label-after {
      width: 60% !important; }
  .text-input .label-before,
  .text-input .label-after {
    display: inline-block; }
  .text-input .label-after {
    margin-left: 0.3rem; }

input[type="text"] {
  padding: 0.3rem 0.5rem; }

.input-timepicker input {
  width: 200px; }

.react-time-picker__inputGroup__input {
  padding: 5px;
  width: 15px !important;
  min-width: 0.54em;
  height: 100%;
  position: relative;
  padding: 0 1px;
  border: 0;
  background: none;
  font: inherit;
  box-sizing: content-box;
  -webkit-appearance: textfield;
  -moz-appearance: textfield; }

.react-time-picker__inputGroup__divider {
  padding-right: 1px; }

.input-medication-block {
  margin-bottom: 2.5rem;
  overflow: hidden; }

.med-block-header {
  padding: 5px;
  width: 10%;
  border-top: 1px solid #DDE0E1; }

.med-block-items-row {
  border-bottom: 1px solid #DDE0E1; }

.med-block-items {
  margin-bottom: 1.5rem;
  border-left: 1px solid #DDE0E1;
  border-right: 1px solid #DDE0E1; }
  .med-block-items .item-col {
    width: 20%;
    padding: 10px 5px;
    vertical-align: middle; }

.med-block-header .val-col, .med-block-header.val-col,
.med-block-items .val-col,
.med-block-items.val-col {
  width: 10%; }

.med-block-header .therapy-col, .med-block-header.therapy-col,
.med-block-header .current-col, .med-block-header.current-col,
.med-block-items .therapy-col,
.med-block-items.therapy-col,
.med-block-items .current-col,
.med-block-items.current-col {
  width: 15%; }

.hide_input {
  display: none; }

.input-container {
  margin-bottom: 1rem; }

label + input[type="text"],
label + select {
  margin-left: 0.5rem; }

label {
  margin-bottom: 0; }

.top-label {
  color: #29BBB3;
  font-size: 0.65rem;
  font-weight: 900;
  text-transform: uppercase; }

input[type=text]:focus,
select:focus {
  outline-color: #29BBB3;
  border-color: #29BBB3; }

.input-field {
  margin-bottom: .35rem; }

.no_gutter {
  padding-left: 0 !important;
  padding-right: 0 !important; }

:global(hr) {
  border-color: #DDE0E1; }

:global(.text_uppercase) {
  text-transform: uppercase; }

:global(.colour-teal) {
  color: #29BBB3; }

:global(.btn-primary) {
  background-color: #29BBB3;
  color: white;
  border-color: transparent; }

:global(.btn.disabled) {
  background-color: #D5D8DA;
  color: #91999E; }

:global(.icon-small) {
  margin-left: .5rem;
  font-size: 0.6rem;
  vertical-align: baseline; }

h4 {
  font-size: 1.08rem;
  font-weight: 600; }

.semi-bold {
  font-weight: 600; }

.message-box {
  position: relative;
  background: #f6b515;
  background: linear-gradient(90deg, #f6b515 0%, #f69615 100%);
  padding: 2rem;
  margin-bottom: 1rem;
  color: white;
  font-size: 0.9rem; }
  .message-box a {
    color: white !important;
    text-decoration: underline; }
  .message-box .col-left {
    align-self: center; }
  .message-box .col-right {
    text-align: right; }
  .message-box .upgrade-btn {
    background-color: transparent !important;
    border: 2px solid white;
    padding: 0.75rem 0.7rem !important;
    line-height: 1;
    text-transform: uppercase; }

.paper-format {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%); }

.react-datepicker-wrapper,
.react-datepicker__input-container {
  width: 100%; }

.react-datepicker-popper {
  width: 400px; }

.login-container {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  width: 100%;
  left: 0; }
  .login-container .login-wrapper {
    max-width: 450px;
    margin: 0 auto; }
  .login-container .login-logo {
    margin-bottom: 3.2rem;
    text-align: center; }
  .login-container .login-box {
    background-color: white;
    padding: 2.5rem;
    margin-bottom: 2rem; }
    .login-container .login-box h2 {
      font-weight: 600; }
    .login-container .login-box input {
      width: 100%; }
    .login-container .login-box input[type="password"] {
      margin-bottom: 1rem; }
    .login-container .login-box button {
      margin-top: 3rem;
      width: 200px; }
    .login-container .login-box a {
      text-decoration: underline; }
  .login-container .login-links {
    margin-bottom: 4rem;
    color: white; }
    .login-container .login-links button {
      background-color: transparent;
      margin-left: 1rem;
      padding: 0.5rem 1.3rem;
      color: #29BBB3;
      border: 2px solid #29BBB3; }
  .login-container .footer-links {
    text-align: center; }
    .login-container .footer-links ul {
      margin: 0;
      padding: 0; }
      .login-container .footer-links ul li {
        display: inline-block; }
        .login-container .footer-links ul li:before {
          content: '\00B7';
          color: white;
          margin: 0 0.5rem; }
        .login-container .footer-links ul li:first-child:before, .login-container .footer-links ul li:last-child:after {
          display: none; }
    .login-container .footer-links a {
      color: white; }

.icon-Triangle.reverse {
  transform: rotate(180deg); }

.content-editable {
  overflow-y: scroll;
  padding: 20px 15px;
  max-height: 170px;
  border: 1px solid #DDE0E1;
  -webkit-touch-callout: all;
  /* iOS Safari */
  -webkit-user-select: all;
  /* Safari */
  -khtml-user-select: all;
  /* Konqueror HTML */
  -moz-user-select: all;
  /* Firefox */
  -ms-user-select: all;
  /* Internet Explorer/Edge */
  user-select: all;
  /* Chrome and Opera */ }
  .content-editable.plain-text {
    font-family: 'Courier New', Courier, monospace; }
  .content-editable h1 {
    font-size: 130%; }
  .content-editable h2 {
    font-size: 110%; }
  .content-editable h3 {
    font-size: 100%; }
  .content-editable h1,
  .content-editable h2,
  .content-editable h3,
  .content-editable h4 {
    font-weight: bold;
    text-decoration: underline; }
  .content-editable h1,
  .content-editable h2,
  .content-editable h3,
  .content-editable h4,
  .content-editable h5,
  .content-editable h6,
  .content-editable p {
    margin: 0; }

:global(button),
:global(.btn),
:global(input[type="submit"]) {
  background-color: #29BBB3;
  border: 0;
  border-radius: 3px;
  color: white;
  font-weight: 700;
  font-size: 0.88rem;
  padding: 0.75rem 1.3rem;
  text-align: center;
  cursor: pointer; }
  :global(button):focus,
  :global(.btn):focus,
  :global(input[type="submit"]):focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  :global(button):hover,
  :global(.btn):hover,
  :global(input[type="submit"]):hover {
    background-color: #668087;
    color: white; }

a.btn {
  text-decoration: none; }

.teal-border-btn {
  background-color: transparent;
  border: 2px solid #29BBB3;
  color: #29BBB3;
  font-size: 0.8rem; }
  .teal-border-btn:hover {
    background-color: transparent;
    color: white;
    border-color: white; }

.teal-solid-btn:hover {
  background-color: white;
  color: #29BBB3; }

#medication-history-modal input[type="radio"],
#medication-history-modal input[type="checkbox"] {
  display: inline;
  width: auto; }

:global(.modal-dialog) {
  max-width: 700px;
  top: 50%;
  transform: translateY(-50%) !important;
  -webkit-transform: translateY(-50%) !important;
  -moz-transform: translateY(-50%) !important;
  -ms-transform: translateY(-50%) !important; }
  :global(.modal-dialog) .close {
    position: absolute;
    right: 0;
    background-color: transparent !important;
    color: #29BBB3 !important;
    opacity: 1;
    text-shadow: none; }
    :global(.modal-dialog) .close span {
      display: inline-block;
      padding: 0.8rem; }
    :global(.modal-dialog) .close:hover {
      color: #42515A !important; }

:global(.modal-content) {
  box-shadow: 0 0 7px 0 rgba(25, 36, 42, 0.27) !important;
  border-radius: 0 !important;
  border: 0 !important; }
  :global(.modal-content) a {
    color: #29BBB3;
    text-decoration: underline; }
    :global(.modal-content) a.btn {
      color: white;
      text-decoration: none; }

:global(.modal-header) {
  padding: 2rem 2rem 1rem !important;
  border-bottom: 0 !important;
  border-radius: 0 !important; }

:global(.modal-body) {
  padding: 0 2rem 1rem !important; }
  :global(.modal-body) .small-text-format {
    margin-top: 0;
    margin-bottom: 0.3rem;
    color: #29BBB3;
    font-weight: 700; }
  :global(.modal-body) .radio_input {
    margin-bottom: 0.4rem; }
  :global(.modal-body) .modal-link {
    color: #91999E;
    font-size: 0.75rem;
    font-weight: 700;
    text-transform: uppercase;
    text-shadow: 0 1px 0 0 #FFFFFF;
    cursor: pointer; }
  :global(.modal-body) input,
  :global(.modal-body) .select_input,
  :global(.modal-body) textarea {
    width: 100%; }
  :global(.modal-body) input[type="text"] {
    padding: 0.45rem 0.5rem; }
  :global(.modal-body) textarea {
    padding: 0.75rem;
    margin-bottom: 1rem; }
  :global(.modal-body) .div-container {
    position: relative; }

:global(.modal-title) {
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.2 !important; }

:global(.modal-footer) {
  padding: 0 2rem 2rem !important;
  border-top: 0 !important;
  justify-content: flex-start !important; }
  :global(.modal-footer) .modal-footer-container {
    display: flex;
    align-items: center;
    width: 100%; }
    :global(.modal-footer) .modal-footer-container > :not(:first-child) {
      margin-left: 1.7rem !important; }
    :global(.modal-footer) .modal-footer-container button {
      width: 150px; }
    :global(.modal-footer) .modal-footer-container .cancel-btn {
      border-bottom: 1px solid #29BBB3;
      cursor: pointer;
      font-size: 0.88rem;
      font-weight: 700;
      text-transform: uppercase; }

.modal-add-pharmacy.modal-dialog {
  max-width: 500px !important; }

#pharmacy-modal hr:last-child {
  margin-top: 2rem; }

#add-pharmacy-modal .half-input .select_input {
  display: inline-block;
  width: 48%; }
  #add-pharmacy-modal .half-input .select_input:first-child {
    margin-right: 4%; }

#add-organization-modal {
  max-width: 454px; }

#export-note-modal {
  max-width: 560px; }
  #export-note-modal .modal-title span {
    margin-left: 0.8rem;
    font-size: 0.88rem;
    font-weight: normal; }
  #export-note-modal .modal-body .row .col:first-child {
    padding-right: 8px; }
  #export-note-modal .modal-body .row .col:last-child {
    padding-left: 8px; }
  #export-note-modal .export-btn {
    padding: 1.5rem 1.2rem !important;
    margin-bottom: 1rem;
    width: 100%;
    text-transform: uppercase; }
    #export-note-modal .export-btn span {
      margin-right: 0.5rem;
      font-size: 1.2rem; }

#save-new-template-modal {
  max-width: 450px; }
  #save-new-template-modal .modal-footer .modal-footer-container {
    border-top: 0;
    padding-top: 0; }

#copy-recovery-modal {
  max-width: 400px; }
  #copy-recovery-modal .tool-tip {
    left: 90%;
    bottom: 60%;
    min-width: 70px; }
  #copy-recovery-modal a {
    text-decoration: none; }
  #copy-recovery-modal .cancel-btn:hover {
    border-color: #42515A; }

#download-pdf-modal {
  max-width: 440px; }
  #download-pdf-modal .tool-tip {
    left: 90%;
    bottom: 60%;
    min-width: 70px; }
  #download-pdf-modal .modal-body button,
  #download-pdf-modal .modal-body .btn {
    width: 100%;
    font-size: 0.77rem !important; }
  #download-pdf-modal .modal-body .row {
    margin-bottom: 1.2rem; }

#output-text-modal {
  max-width: 480px; }
  #output-text-modal .btn-full-width {
    width: 100%; }
  #output-text-modal textarea {
    min-height: 160px; }
  #output-text-modal .rich-text h1, #output-text-modal .rich-text h2, #output-text-modal .rich-text h3, #output-text-modal .rich-text h4, #output-text-modal .rich-text h5, #output-text-modal .rich-text h6,
  #output-text-modal .rich-text p {
    display: inline; }
  #output-text-modal .rich-text ul,
  #output-text-modal .rich-text ol {
    display: inline;
    margin: 0; }

#open-saved {
  max-width: 420px; }

#choose-workspace-modal {
  max-width: 420px; }
  #choose-workspace-modal ul {
    padding: 0 0 0 1rem; }
    #choose-workspace-modal ul li {
      list-style: none;
      color: #29BBB3;
      font-weight: 600;
      cursor: pointer; }
      #choose-workspace-modal ul li:hover {
        color: #19242A; }

.popups {
  display: none;
  position: absolute;
  background-color: #42515A;
  color: white;
  top: 47px;
  left: 50%;
  min-width: 116px;
  transform: translateX(-50%);
  border-radius: 3px;
  z-index: 10; }
  .popups ul {
    list-style: none;
    margin: 0;
    padding: 1.2rem 1.1rem 0.8rem; }
    .popups ul li {
      font-size: 0.8rem;
      margin-bottom: 0.4rem;
      cursor: pointer; }
      .popups ul li label {
        font-size: 0.87rem;
        padding-top: 0.18rem; }
      .popups ul li ul {
        padding: 0.1rem 0 0 1.4rem; }
        .popups ul li ul li {
          font-weight: 400; }
  .popups.open {
    display: block; }
  .popups .checkbox-input label:before {
    background-color: #668087; }
  .popups .checkbox-input input[type="checkbox"]:checked + label::before {
    background-color: #668087; }
  .popups.template_filter {
    width: 230px; }
    .popups.template_filter ul li {
      margin-bottom: 0.1rem; }
  .popups:before {
    content: '';
    position: absolute;
    top: -5px;
    left: 45%;
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 6px solid #42515A; }

.popup_label {
  color: #91999E;
  font-size: 0.75rem;
  font-weight: 700;
  text-align: center;
  cursor: pointer; }
  .popup_label .filter-label {
    top: 0;
    font-size: 0.75rem;
    margin-left: 0; }
  .popup_label span {
    position: relative;
    display: inline-block;
    top: 3px;
    font-size: 1.03rem;
    margin-left: 0.2rem; }

.pdf-outline-filter {
  padding: 0.3rem 0.3rem; }

.pdf-settings-outline .popup_label .filter-label {
  display: none; }

@media (min-width: 1400px) {
  .pdf-settings-outline .popup_label .filter-label {
    display: inline-block; } }

.tool-tip {
  display: none;
  position: absolute;
  background-color: #19242A;
  padding: 0.7rem;
  color: white;
  bottom: 130%;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 3px;
  min-width: 170px;
  font-size: 0.7rem;
  z-index: 10;
  text-transform: none; }
  .tool-tip a {
    display: inline-block;
    color: #29BBB3;
    text-decoration: underline; }
    .tool-tip a:hover {
      color: white;
      text-decoration: underline !important; }
  .tool-tip:after {
    content: '';
    position: absolute;
    bottom: -5px;
    left: 45%;
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid #19242A; }
  .tool-tip.open {
    display: block; }

.user-navigation .tool-tip,
.sidebar-navigation .tool-tip,
.bottom-navigation .tool-tip {
  background-color: #29BBB3;
  bottom: 108%;
  padding: 0.5rem 0.2rem;
  min-width: 68px;
  max-width: 68px;
  text-transform: uppercase; }
  .user-navigation .tool-tip:after,
  .sidebar-navigation .tool-tip:after,
  .bottom-navigation .tool-tip:after {
    left: 41%;
    border-top: 6px solid #29BBB3; }

.sidebar-col {
  flex-grow: 0;
  width: 280px; }

.sidebar {
  position: fixed;
  height: 100%;
  width: 280px;
  left: -200px;
  background: #19242A;
  font-size: 1.15rem;
  transition: 0.5s;
  z-index: 500; }
  .sidebar .user-block {
    margin-bottom: 1.5rem;
    cursor: pointer; }
    .sidebar .user-block .username {
      font-size: 0.88rem;
      font-weight: 700;
      letter-spacing: 0.1px;
      text-transform: uppercase; }
    .sidebar .user-block .name {
      display: inline-block; }
    .sidebar .user-block .icon span {
      position: relative;
      top: 10px;
      font-size: 1.5rem; }
  .sidebar .sidebar_container {
    height: 100%;
    color: #55646D; }
    .sidebar .sidebar_container ul {
      padding-top: 1.4rem;
      padding-bottom: 1.2rem;
      border-top: 1px solid #0D1317;
      border-bottom: 1px solid #0D1317; }
      .sidebar .sidebar_container ul li {
        position: relative; }
      .sidebar .sidebar_container ul .nav-text {
        color: #55646D;
        font-size: 1.15rem; }
        .sidebar .sidebar_container ul .nav-text.active, .sidebar .sidebar_container ul .nav-text:hover {
          color: white;
          font-weight: bold; }
      .sidebar .sidebar_container ul > a {
        font-weight: 700; }
      .sidebar .sidebar_container ul span.menu-icon {
        position: relative;
        margin-right: 0.5rem;
        font-size: 1.5rem;
        top: 4px; }
    .sidebar .sidebar_container span.mini-icon {
      margin-top: .35rem;
      font-size: .5rem; }
    .sidebar .sidebar_container .logo-large {
      background-color: #0D1317;
      padding: 1.5rem 1.8rem;
      margin-bottom: 25px; }
      .sidebar .sidebar_container .logo-large svg {
        max-width: 190px; }
    .sidebar .sidebar_container .logo-small {
      background-color: #0D1317;
      padding: 0.5rem 0;
      margin: 0;
      list-style: none; }
    .sidebar .sidebar_container .nav-container {
      padding: 0rem 1.8rem; }
    .sidebar .sidebar_container .collapse_btn {
      cursor: pointer; }
      .sidebar .sidebar_container .collapse_btn.active, .sidebar .sidebar_container .collapse_btn:hover {
        color: white; }
  .sidebar .upgrade-btn {
    background: #f6b515;
    background: linear-gradient(134.36deg, #F6B515 0%, #F69715 100%);
    border: 0;
    margin-top: 2rem;
    text-transform: uppercase; }
    .sidebar .upgrade-btn a {
      display: inline-block;
      color: white;
      line-height: 1; }
    .sidebar .upgrade-btn .icon-font {
      position: relative;
      top: 1px;
      margin-right: 0.4rem;
      font-size: 1.08rem; }
    .sidebar .upgrade-btn:hover {
      background: white;
      background: linear-gradient(134.36deg, white 0%, white 100%); }
      .sidebar .upgrade-btn:hover a {
        color: #F69715; }

.sidebar-navigation {
  list-style: none;
  margin: 0;
  padding: 0; }
  .sidebar-navigation li {
    padding-bottom: 1.3rem; }
    .sidebar-navigation li a {
      color: #55646D;
      font-size: 1.15rem; }
      .sidebar-navigation li a.active, .sidebar-navigation li a:hover {
        color: white;
        font-weight: bold; }
    .sidebar-navigation li:last-child {
      padding-bottom: 0.5rem; }

.bottom-navigation {
  position: absolute;
  bottom: 1.7rem;
  padding-bottom: 0 !important;
  border: 0 !important;
  list-style: none; }
  .bottom-navigation li:last-child {
    padding-bottom: 0; }
  .bottom-navigation .reverse {
    display: inline-block;
    transform: rotate(-180deg); }

.collapsed {
  width: 280px !important;
  left: -200px !important; }
  .collapsed .collapsed_menu {
    opacity: 1; }

.collapsed_menu button,
.bottom-navigation button {
  background-color: transparent !important;
  padding: 0;
  color: #55646D !important;
  padding: 0 !important;
  font-weight: 400 !important;
  font-size: inherit !important; }
  .collapsed_menu button:hover,
  .bottom-navigation button:hover {
    color: white !important;
    font-weight: 700 !important; }
  .collapsed_menu button:focus,
  .bottom-navigation button:focus {
    box-shadow: none;
    /*outline-style: dashed;
      outline-color: $blue-grey;
      outline-width: 1px;*/ }

.collapsed_menu {
  position: absolute;
  background: #19242A;
  width: 80px;
  height: 100%;
  top: 0;
  left: 200px;
  text-align: center;
  opacity: 1; }
  .collapsed_menu svg {
    max-width: 40px; }
  .collapsed_menu ul span {
    font-size: 1.9rem;
    margin-right: 0; }
  .collapsed_menu ul .nav-text {
    display: none; }
  .collapsed_menu .user-navigation {
    padding: 0.5rem 0 0.3rem !important;
    margin: 0; }
  .collapsed_menu .user-block .pharmacy-name,
  .collapsed_menu .user-block .username {
    display: none; }
  .collapsed_menu .user-block .icon {
    flex: none;
    max-width: 100%;
    text-align: center; }
  .collapsed_menu .sidebar-navigation {
    border-top: 2px solid #0D1317;
    border-bottom: 2px solid #0D1317; }
  .collapsed_menu .upgrade-nav {
    border: 0 !important; }
  .collapsed_menu .upgrade-btn {
    background: transparent;
    margin-top: 0; }
    .collapsed_menu .upgrade-btn .icon-font {
      font-size: 1.9rem; }
    .collapsed_menu .upgrade-btn span {
      color: #F69715; }
    .collapsed_menu .upgrade-btn:hover {
      background: transparent; }
      .collapsed_menu .upgrade-btn:hover span {
        color: white; }
  .collapsed_menu .bottom-navigation {
    padding: 0;
    margin: 0 auto;
    width: 100%; }
    .collapsed_menu .bottom-navigation li {
      width: 100%;
      margin-bottom: 1rem; }
      .collapsed_menu .bottom-navigation li:last-child {
        margin-bottom: 0; }

.sidebar-open .sidebar {
  width: 250px;
  left: 0; }

.sidebar-open .collapsed_menu {
  opacity: 0; }

:global(html) {
  font-size: 14px; }

:global(body) {
  font-family: 'Catamaran', sans-serif;
  color: #42515A; }

a {
  color: #29BBB3;
  transition: 0.2s; }

a:hover {
  color: #19242A;
  text-decoration: none; }

body,
html,
#root,
.App {
  width: 100%; }

body,
html,
#root,
.App,
.App > .container-fluid,
.App > .container-fluid > Row,
.main_content {
  height: 100%; }

.App {
  background-color: #EDEDED; }
  .App.login-page {
    background-color: #19242A; }
  .App.login-page,
  .App.login-page > .container-fluid,
  .App.login-page > .container-fluid > Row,
  .App.login-page .main_content {
    height: 100%; }

.main-header {
  font-size: 1.5rem;
  font-weight: 600; }

.small-text-format {
  margin-top: 18px;
  font-size: 10px;
  font-weight: 500;
  text-transform: uppercase; }

.small-header {
  color: #29BBB3;
  font-weight: 900; }

.small-text {
  font-size: 10px; }

.med-text {
  font-size: 0.88rem; }

.main_content {
  background-color: #EDEDED;
  margin-left: 80px;
  width: 100%;
  height: 100%; }

.sidebar-open .sidebar {
  width: 250px;
  left: 0; }

.sidebar-open .collapsed_menu {
  opacity: 0; }

@media (min-width: 1200px) {
  .sidebar-open .main_content {
    margin-left: 250px; } }
