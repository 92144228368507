.popups {
  display: none;
  position: absolute;
  background-color: #42515A;
  color: white;
  top: 47px;
  left: 50%;
  min-width: 116px;
  transform: translateX(-50%);
  border-radius: 3px;
  z-index: 10;

  ul {
    list-style: none;
    margin: 0;
    padding: 1.2rem 1.1rem 0.8rem;

    li {
      font-size: 0.8rem;
      margin-bottom: 0.4rem;
      cursor: pointer;

      label {
        font-size: 0.87rem;
        padding-top: 0.18rem;
      }

      ul {
        padding: 0.1rem 0 0 1.4rem;

        li { font-weight: 400; }
      }
    }
  }

  &.open {
    display: block;
  }

  .checkbox-input {
    label:before {
      background-color: #668087;
    }

    input[type="checkbox"]:checked + label::before {
      background-color: #668087;
    }
  }

  &.template_filter {
    width: 230px;

    ul {
      li { margin-bottom: 0.1rem; }
    }
  }

  &:before {
    content: '';
    position: absolute;
    top: -5px;
    left: 45%;
    width: 0; 
    height: 0; 
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 6px solid #42515A;
  }
}

.popup_label {
  color: #91999E;
  font-size: 0.75rem;
  font-weight: 700;
  text-align: center;
  cursor: pointer;

  .filter-label {
    top: 0;
    font-size: 0.75rem;
    margin-left: 0;
  }

  span {
    position: relative;
    display: inline-block;
    top: 3px;
    font-size: 1.03rem;
    margin-left: 0.2rem;
  }
}

.pdf-outline-filter {
  padding: 0.3rem 0.3rem;
}

.pdf-settings-outline {
  .popup_label {
    .filter-label { display: none; }
  }
}

@media(min-width:1400px) {
  .pdf-settings-outline {
    .popup_label {
      .filter-label { display: inline-block; }
    }
  }
}