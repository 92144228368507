@import '../../../scss/colours';

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #91999E;
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #91999E;
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: #91999E;
}

.search_box {
  background-color: $light-white;
  border: 0;
  border-radius: 2px;
  font-size: 1rem;
  padding: 7px 14px;
  padding-left: 20px;
  width: 100%;
}

.search_input_container {
  position: relative;
  //padding-right: 0;

  span {
    position: absolute;
    top: 0.55rem;
    left: 1.4rem;
    font-size: 1.2rem;
    color: #91999E;
  }

  input[type="text"] {
    padding-left: 2rem;
  }
}

.filter_item {
  position: relative;
  display: inline-block;
  margin-right: 1rem;
  border-radius: 18px;

  &:hover,
  &.active {
    background-color: $light-white;
  }
}

.selected {
  font-weight: 700;
}

.template_filter {
  width: 210px;
}

.filter_term {
  display: inline-block;
  background: $teal;
  padding: 0.3rem 0.6rem 0.2rem;
  margin-right: 0.5rem;
  margin-bottom: .5rem;
  color: white;
  font-size: 0.65rem;
  border-radius: 1rem;
  //line-height: 0.6;
  font-weight: 700;
  text-transform: uppercase;

  span {
    margin-left: 0.5rem;
    font-size: 0.55rem;
    cursor: pointer;
  }
}

.clear_all_filter {
  display: inline-block;
  cursor: pointer;
  margin-left: 1rem;
  color: $teal;
  font-size: 0.7rem;
  font-weight: bold;
  border-bottom: 1px solid $teal;
  text-transform: uppercase;
}

.total_results {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 0.7rem;
  text-align: right;
}

.search_full_width {
  .filter_item {
    margin-top: 1rem;
  }
}

@media( min-width: 1200px ) {
  .filter_item {
    padding: 0.5rem 0.8rem;
  }
}

@media( min-width: 1450px ) {
  .filter_item {
    padding: 0.5rem 1.1rem;
  }

  /*.search_input_container {
    flex: 0 0 58%;
    max-width: 58%;
  }*/

  /*.filters {
    flex: 0 0 42%;
    max-width: 42%;
  }*/
}