@import '../../../scss/breakpoints';
@import '../../../scss/colours';

.template_row_header {
  padding: 25px 25px 0;
}

.header_templates {
  flex: 0 0 33%;
  max-width: 33%;
}

.header_format {
  flex: 0 0 30%;
  max-width: 30%;
}

.header_btn {
  flex: 0 0 25%;
  max-width: 25%;
  padding-right: 0;
}

.header_owner {
  //flex: 0 0 23%;
  //max-width: 23%;
}

.header_owner + .header_format {
  flex: 0 0 23%;
  max-width: 23%;
}

.header_owner + .header_format + .header_btn {
  flex: 0 0 21%;
  max-width: 21%;
}

.template_header {
  color: $teal;
  font-size: 0.75rem;
  font-weight: bold;
  text-transform: uppercase;
}

.template_no_results {
  padding: 2rem;
}

@media(min-width:$xl) {
  .header_templates {
    flex: 0 0 45%;
    max-width: 45%;
  }

  .header_owner {
    flex: 0 0 23%;
    max-width: 23%;
  }

  .header_format,
  .header_btn {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .header_owner + .header_format,
  .header_owner + .header_format + .header_btn {
    flex: 0 0 16%;
    max-width: 16%;
  }
}