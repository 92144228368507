.input-group {
  align-items: center;
}

.group-item {
  width: 100%;
  //margin-bottom: .5rem;
}

/*.group-item-text {
  display: block;
  width: 100%;
}

.group-item-input-text {
  .text-input { margin-bottom: 0; }
  .no-label-before.no-label-after { width: 100%; }
}

.group-item-checkbox {
  //width: 8%;

  .checkbox-input label.empty-label {
    padding-left: 20px;
  }
}

.group-item-checkbox + .group-item-select {
  width: 33%;
}

.group-item-select:last-child {
  width: 80%;
}

.group-item-checkbox.has-label + .group-item-select:last-child {
  width: 70%;
}

/*.group-item-checkbox.has-label + .group-item-input-text:last-child {
  width: 40%;
}*/

/*.group-item-radio + .group-item-date:last-child {
  width: 50%;
}*/

/*.group-item-checkbox + .group-item-input-text,
.group-item-radio + .group-item-input-text,
.group-item-radio + .group-item-select {
  margin-left: 0.5rem;
}

.group-item-date {
  width: 100%;

  .react-datepicker-wrapper {
    margin-left: 0.5rem;
    width: 30%;
  }
}*/

.input-radio-input-text {
  input[type=radio]:checked ~ input[type=text] {
    border-color: $teal;
  }
}

.input-checkbox-input-text {
  input[type=checkbox]:checked + input[type=text] {
    border-color: $teal;
  }
}

.input-checkbox-input-select {
  .checkbox-input,
  .select-input {
    display: inline-block;
  }

  .checkbox-input {
    width: 9%;
  }

  .select-input { width: 79%; }
}

.grid_v {
  .group-item { width: 100%; }

  .input-checkbox-input-text,
  .input-radio-input-text {
    .text-input {
      display: inline-block;
      padding-left: .5rem;
      width: 60%;

      input[type="text"] { width: 100%; }
    }
  }

  .input-checkbox-input-text,
  .input-checkbox-input-date {
    .checkbox-input {
      display: inline-block;
    }
  }

  .input-radio-input-text,
  .input-radio-input-date {
    .radio-input {
      display: inline-block;
    }
  }

  .input-checkbox-input-date,
  .input-radio-input-date {
    .date-input {
      display: inline-block;
      margin-left: .5rem;
    }
  }

  .input-radio-input-text-input-date {
    .radio-input {
      display: inline-block;
    }

    .text-input {
      display: inline-block;
      padding-left: .5rem;
      width: 50%;

      input[type="text"] { width: 100%; }
    }

    .date-input {
      display: inline-block;
      margin-left: 1.4rem;
    }
  }

  .input-date-input-select {
    .date-input {
      display: inline-block;
      margin-bottom: .5rem;
    }
  }

  .input-checkbox-input-select {
    .checkbox-input {
      
    }

    .select-input {
      //width: 90%;
    }
  }
}

// Horizontal Grid - Input Styling
.grid_h {
  .input-checkbox-input-text {
    .group-item { width: 100%; }

    .has-label,
    .checkbox-input { width: 100%; }

    .text-input {
      width: 90%;
      padding-left: 28px;

      input[type="text"] { width: 100%; }
    }
  }

  .input-radio-input-text {
    .group-item { width: 100%; }

    .has-label,
    .radio-input { width: 100%; }

    .text-input {
      width: 90%;
      padding-left: 28px;

      input[type="text"] { width: 100%; }
    }
  }

  
}