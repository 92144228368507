.input-medication-block {
  margin-bottom: 2.5rem;
  overflow: hidden;
}

.med-block-header {
  padding: 5px;
  width: 10%;
  border-top: 1px solid $border-grey;
}

.med-block-items-row {
  border-bottom: 1px solid $border-grey;
}

.med-block-items {
  margin-bottom: 1.5rem;
  border-left: 1px solid $border-grey;
  border-right: 1px solid $border-grey;

  .item-col {
    width: 20%;
    padding: 10px 5px;
    vertical-align: middle;
  }
}

.med-block-header,
.med-block-items {
  .val-col,
  &.val-col {
    width: 10%;
  }

  .therapy-col,
  &.therapy-col,
  .current-col,
  &.current-col {
    width: 15%;
  }
}