.widget_container {
  background-color: white;
  padding: 2.8rem;
  height: 100%;
  font-size: 0.93rem;

  label { margin-top: 0; }
  input { margin-bottom: 1rem; }

  .widget_btn {
    vertical-align: top;
  }

  .widget_upgrade_message {
    display: inline-block;
    margin-left: 3%;
    margin-top: 0.6rem;
    width: 109%;
    max-width: 180px;
  }

  a {
    text-decoration: underline;

    &:hover { text-decoration: underline; }
  }
}

.tablet_only {
  display: block;
}

@media(min-width:1024px) {
  .widget_container {
    .widget_upgrade_message {
      margin-top: 0;
      margin-left: 0;
      width: 39%;
    }
  }
}

@media(min-width:1200px) {
  .tablet_only {
    display: none;
  }

  .widget_container {
    .widget_upgrade_message {
      display: block;
      margin-top: 0.6rem;
      width: 100%;
    }
  }
}

@media(min-width:1590px) {
  .widget_container {
    .widget_upgrade_message {
      display: inline-block;
      margin-left: 3%;
      margin-top: 0;
      width: 31%;
    }
  }
}

@media(min-width:1600px) {
  .widget_container {
    .widget_upgrade_message {
      width: 35%;
    }
  }
}

@media(min-width:1859px) {
  .widget_container {
    .widget_upgrade_message {
      margin-top: 0.47rem;
      width: 40%;
    }
  }
}