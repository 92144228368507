.input-date {
  //padding: 0.3rem 0.5rem;
  //margin-bottom: .5rem;
}

.date-input {
  label {
    display: inline-block;
  }

  .react-datepicker-wrapper { width: 50%; }
  .react-datepicker-popper { z-index: 50; }

  input[type="text"] {
    padding: 0.3rem 0.5rem 0;
    border: 0;
    border-bottom: 1px solid $main-grey;
  }
}