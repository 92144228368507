@import './scss/inputs/checkbox';
@import './scss/inputs/date';
@import './scss/inputs/group';
@import './scss/inputs/radio';
@import './scss/inputs/select';
@import './scss/inputs/text';
@import './scss/inputs/textarea';
@import './scss/inputs/text_input';
@import './scss/inputs/timepicker';
@import './scss/inputs/medication_block';

.hide_input {
  display: none;
}

.input-container {
  margin-bottom: 1rem;
}

label + input[type="text"],
label + select {
  margin-left: 0.5rem;
}

label {
  margin-bottom: 0;
}

.top-label {
  color: $teal;
  font-size: 0.65rem;
  font-weight: 900;
  text-transform: uppercase;
}

input[type=text]:focus,
select:focus {
  outline-color: $teal;
  border-color: $teal;
}

.input-field {
  margin-bottom: .35rem;
}