.sidebar-col {
  flex-grow: 0;
  width: 280px;
}

.sidebar {
  position: fixed;
  //padding: 1.8rem;
  height: 100%;
  width: 280px;
  left: -200px;
  background: $grey;
  font-size: 1.15rem;
  transition: 0.5s;
  z-index: 500;

  .user-block {
    margin-bottom: 1.5rem;
    cursor: pointer;

    .username {
      font-size: 0.88rem;
      font-weight: 700;
      letter-spacing: 0.1px;
      text-transform: uppercase;
    }
    
    .name {
      display: inline-block;
    }
    
    .icon {
      span {
        position: relative;
        top: 10px;
        font-size: 1.5rem;
      }
    }
  }

  .sidebar_container {
    height: 100%;
    color: $blue-grey;

    ul {
      padding-top: 1.4rem;
      padding-bottom: 1.2rem;
      border-top: 1px solid #0D1317;
      border-bottom: 1px solid #0D1317;

      li { position: relative; }

      .nav-text {
        color: $blue-grey;
        font-size: 1.15rem;

        &.active,
        &:hover {
          color: white;
          font-weight: bold;
        }
      }

      & > a { font-weight: 700; }

      span.menu-icon {
        position: relative;
        margin-right: 0.5rem;
        font-size: 1.5rem;
        top: 4px;
      }
    }

    span.mini-icon {
      margin-top: .35rem;
      font-size: .5rem;
    }

    .logo-large {
      background-color: #0D1317;
      padding: 1.5rem 1.8rem;
      margin-bottom: 25px;
      
      svg { max-width: 190px; }
    }

    .logo-small {
      background-color: #0D1317;
      padding: 0.5rem 0;
      margin: 0;
      list-style: none;
    }

    .nav-container {
      padding: 0rem 1.8rem;
    }

    .collapse_btn {
      cursor: pointer;
      //font-weight: 700;

      &.active,
      &:hover {
        color: white;
      }
    }
  }

  .upgrade-btn {
    background: rgb(246,181,21);
    background: linear-gradient(134.36deg, #F6B515 0%, #F69715 100%);
    border: 0;
    margin-top: 2rem;
    text-transform: uppercase;

    a { 
      display: inline-block;
      color: white;
      line-height: 1;
    }

    .icon-font { 
      position: relative;
      top: 1px;
      margin-right: 0.4rem;
      font-size: 1.08rem;
    }

    &:hover {
      background: white;
      background: linear-gradient(134.36deg, white 0%, white 100%);

      a { color: #F69715; }
    }
  }
}

.sidebar-navigation {
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    padding-bottom: 1.3rem;

    a {
      color: $blue-grey;
      font-size: 1.15rem;

      &.active,
      &:hover {
        color: white;
        font-weight: bold;
      }
    }

    &:last-child {
      padding-bottom: 0.5rem;
    }
  }
}

.bottom-navigation {
  position: absolute;
  bottom: 1.7rem;
  padding-bottom: 0!important;
  border: 0!important;
  list-style: none;

  li:last-child {
    padding-bottom: 0;
  }

  .reverse {
    display: inline-block;
    transform: rotate(-180deg);
  }
}

.collapsed {
  width: 280px!important;
  left: -200px!important;
  
  .collapsed_menu { opacity: 1; }
}

.collapsed_menu,
.bottom-navigation {
  button {
    background-color: transparent!important;
    padding: 0;
    color: $blue-grey!important;
    padding: 0!important;
    font-weight: 400!important;
    font-size: inherit!important;

    &:hover {
      color: white!important;
      font-weight: 700!important;
    }

    &:focus {
      box-shadow: none;
      /*outline-style: dashed;
      outline-color: $blue-grey;
      outline-width: 1px;*/
    }
  }
}

.collapsed_menu {
  position: absolute;
  background: $grey;
  width: 80px;
  height: 100%;
  top: 0;
  left: 200px;
  text-align: center;
  opacity: 1;

  svg { max-width: 40px; }

  ul {
    span {
      font-size: 1.9rem;
      margin-right: 0;
    }

    .nav-text { display: none; }
  }

  .user-navigation {
    padding: 0.5rem 0 0.3rem!important;
    margin: 0;
  }

  .user-block {
    .pharmacy-name,
    .username {
      display: none;
    }

    .icon {
      flex: none;
      max-width: 100%;
      text-align: center;
    }
  }

  .sidebar-navigation {
    border-top: 2px solid #0D1317;
    border-bottom: 2px solid #0D1317;
  }

  .upgrade-nav {
    border: 0!important;
  }

  .upgrade-btn {
    background: transparent;
    margin-top: 0;

    .icon-font { font-size: 1.9rem; }

    span {
      color: #F69715;
    }

    &:hover {
      background: transparent;

      span { color: white; }
    }
  }

  .bottom-navigation {
    padding: 0;
    margin: 0 auto;
    width: 100%;

    li {
      width: 100%;
      margin-bottom: 1rem;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.sidebar-open {
  .sidebar {
    width: 250px;
    left: 0;
  }

  .collapsed_menu { opacity: 0; }
}

@media(min-width:1200px) {
  .sidebar {
    //overflow: hidden;
  }
}