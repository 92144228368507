@import '../../../../scss/breakpoints';
@import '../../../../scss/colours';

.card_block {
  padding: 1rem 0 1rem 1rem;
  border-bottom: 1px solid $border-grey;
  margin-left: 0;
  margin-right: 0;

  &:last-child { border-bottom: 0; }
}

.owner_text {
  font-size: .9rem;
}

.setting_header {
  padding-right: 0;
  font-weight: 600;
  font-size: 1.05rem;
  line-height: 1.2;

  .category {
    font-weight: 400;
    font-size: 0.9rem;
  }
}

.setting_toggle {
  padding-right: 0;
  color: $teal;
  text-transform: uppercase;
  text-decoration: underline;
  font-size: 0.68rem;
  font-weight: 800;
  cursor: pointer;
  align-items: center;
  display: flex;
  justify-content: right;
}