.radio-input {
    //display: inline-block;
    //vertical-align: top;
  
  .radio_option {
    position: relative;
    //margin-right: 2rem;
  
    input[type="radio"] {
      position: absolute;
      opacity: 0;
      width: 15px;
      height: 15px;
      top: 3px;
      width: 100px;
      z-index: 3;
    }
  
    label {
      position: relative;
      display: inline-block;
      padding-left: 22px;
      margin-bottom: 0.2rem;
  
      &::before{
        content: '';
        background-color: $light-white;
        display: inline-block;
        height: 16px;
        width: 16px;
        top: 3px;
        left: 0;
        border-radius: 8px;
      }
  
      &::after {
        content: '';
        display: inline-block;
        background-color: $teal;
        line-height: 1;
        top: 6px;
        //left: 3px;
        left: 0.185rem;
        color: white;
        width: 10px;
        height: 10px;
        border-radius: 5px;
      }
    }
  
    label::before,
    label::after {
      position: absolute;
    }
  
    input[type="radio"] + label::after {
      background-color: transparent;
    }
  
    input[type="radio"]:checked + label::after {
      background-color: $teal;
    }
  
    input[type="checkbox"]:checked + label::before {
      background-color: $teal;
    }
  }
}