@import '../../scss/colours';

.notice {
  padding: 0.5rem 0.5rem 0.3rem;
  line-height: 1.0;
  margin-bottom: 1rem;

  .col_left {
    display: inline-block;
    padding-right: 0;
    width: 20px;
    //width: 6%;
    vertical-align: bottom;
  }

  .col_right {
    display: inline-block;
    padding-left: 0.5rem;
    //width: 94%;
    width: 90%;
  }

  .message {
    line-height: 1.5;
    //font-size: 0.8rem;
    font-weight: 600;
  }

  .icon_font {
    font-size: 1.2rem;
  }

  &.error {
    color: #BC2025;
    border: 2px solid #BC2025;
  }

  &.success {
    color: $teal;
    border: 2px solid $teal;
  }

  &.hide {
    display: none;
  }
}