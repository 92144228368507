@import 'modals/add-medication-history.scss';

:global(.modal-dialog) {
  max-width: 700px;
  top: 50%;
  transform: translateY(-50%)!important;
  -webkit-transform: translateY(-50%)!important;
  -moz-transform: translateY(-50%)!important;
  -ms-transform: translateY(-50%)!important;

  .close {
    position: absolute;
    right: 0;
    background-color: transparent!important;
    color: $teal!important;
    opacity: 1;
    text-shadow: none;

    span {
      display: inline-block;
      padding: 0.8rem;
    }

    &:hover {
      color: $main-grey!important;
    }
  }
}

:global(.modal-content) {
  box-shadow: 0 0 7px 0 rgba(25,36,42,0.27)!important;
  border-radius: 0!important;
  border: 0!important;

  a {
    color: $teal;
    text-decoration: underline;

    &.btn {
      color: white;
      text-decoration: none;
    }
  }
}

:global(.modal-header) {
  padding: 2rem 2rem 1rem!important;
  border-bottom: 0!important;
  border-radius: 0!important;
}

:global(.modal-body) {
  padding: 0 2rem 1rem!important;

  .small-text-format {
    margin-top: 0;
    margin-bottom: 0.3rem;
    color: $teal;
    font-weight: 700;
  }

  .radio_input {
    margin-bottom: 0.4rem;
  }

  .modal-link {
    color:#91999E;
    //color: #BFC6CB;
    font-size: 0.75rem;
    font-weight: 700;
    text-transform: uppercase;
    text-shadow: 0 1px 0 0 #FFFFFF;
    cursor: pointer;
  }

  input,
  .select_input,
  textarea {
    width: 100%;
  }

  input[type="text"] {
    padding: 0.45rem 0.5rem;
  }

  textarea {
    padding: 0.75rem;
    margin-bottom: 1rem;
  }

  .div-container {
    position: relative;
  }
}

:global(.modal-title) {
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.2!important;
}

:global(.modal-footer) {
  padding: 0 2rem 2rem!important;
  border-top: 0!important;
  justify-content: flex-start!important;

  .modal-footer-container {
    display: flex;
    align-items: center;
    width: 100%;

    & > :not(:first-child) {
      margin-left: 1.7rem!important;
    }

    button {
      width: 150px;
    }
  
    .cancel-btn {
      border-bottom: 1px solid $teal;
      cursor: pointer;
      font-size: 0.88rem;
      font-weight: 700;
      text-transform: uppercase;
    }
  }
}

.modal-add-pharmacy.modal-dialog {
  max-width: 500px!important;
}

#pharmacy-modal {
  hr:last-child {
    margin-top: 2rem;
  }
}

#add-pharmacy-modal {
  .half-input {
    .select_input {
      display: inline-block;
      width: 48%;

      &:first-child {
        margin-right: 4%;
      }
    }
  }
}

#add-organization-modal {
  max-width: 454px;
}

#export-note-modal {
  max-width: 560px;

  .modal-title {
    span {
      margin-left: 0.8rem;
      font-size: 0.88rem;
      font-weight: normal;
    }
  }

  .modal-body .row {
    .col:first-child {
      padding-right: 8px;
    }

    .col:last-child {
      padding-left: 8px;
    }
  }

  .export-btn {
    padding: 1.5rem 1.2rem!important;
    margin-bottom: 1rem;
    width: 100%;
    text-transform: uppercase;
    
    span {
      margin-right: 0.5rem;
      font-size: 1.2rem;
    }
  }
}

#save-new-template-modal {
  max-width: 450px;

  .modal-footer .modal-footer-container {
    border-top: 0;
    padding-top: 0;
   }
}

#copy-recovery-modal {
  max-width: 400px;

  .tool-tip {
    left: 90%;
    bottom: 60%;
    min-width: 70px;
  }

  a {
    text-decoration: none;
  }

  .cancel-btn:hover {
    border-color: $main-grey;
  }
}

#download-pdf-modal {
  max-width: 440px;

  .tool-tip {
    left: 90%;
    bottom: 60%;
    min-width: 70px;
  }

  .modal-body {
    button,
    .btn {
      width: 100%;
      font-size: 0.77rem!important;
    }

    .row {
      margin-bottom: 1.2rem;
    }
  }
}

#output-text-modal {
  max-width: 480px;

  .btn-full-width {
    width: 100%;
  }

  textarea {
    min-height: 160px;
  }

  .rich-text {
    h1, h2, h3, h4, h5, h6,
    p {
      display: inline;
    }

    ul,
    ol {
      display: inline;
      margin: 0;
    }
  }
}

#open-saved {
  max-width: 420px;
}

#choose-workspace-modal {
  max-width: 420px;

  ul {
    padding: 0 0 0 1rem;

    li {
      list-style: none;
      color: $teal;
      font-weight: 600;
      cursor: pointer;

      &:hover {
        color: $grey;
      }
    }
  }
}