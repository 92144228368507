@import '../../../scss/variables';
@import '../../../scss/breakpoints';
@import '../../../scss/colours';
@import '../../../scss/mixins';

.customBlockList {
  background: $light-grey;
  padding: 15px;
  margin-bottom: 2rem;
}

.customBlock {
  background-color: white;
  padding: 1rem;
  margin-bottom: 1.5rem;
  border-left: 3px solid $teal;

  .dragIcon {
    color: #BBBBBB;
    cursor: move;
  }

  .closeIcon {
    color: $teal;
    font-size: .7rem;
    cursor: pointer;
  }

  .colRow {
    display: flex;
  }

  .colLeft {
    flex: 0 0 5%;
    max-width: 5%;
  }

  .colRight {
    flex: 0 0 95%;
    max-width: 95%;

    h4 {
      display: inline-block;
    }
  }
}

.default_value {
  display: inline-block;
  margin-left: 1.4rem;
  color: $yellow;
  line-height: 1.2;
  font-size: $small-text;
  font-weight: bold;
  text-transform: uppercase;
  border-bottom: 1px solid $yellow;
  cursor: pointer;
}

.checkbox-input input[type="checkbox"]:checked + label::before {
  background-color: $yellow;
}