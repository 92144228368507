@import '../../scss/colours';
@import '../../scss/mixins';

.template_section {
  padding-top: 140px;
}

.template {
  padding-top: 15px;

  .edit_icon {
    position: relative;
    display: inline-block;
    right: 17px;
    width: 5%;
    cursor: pointer;
    font-size: 0.85rem;
    @include vertical-align-middle;
  }

  .note_header {
    background: #ccc;
    background: url('../../images/QlinicalRx_Header_large.png');
    background-size: 100% 100%;
    margin: 0;
    padding: 1.2rem 0.2rem;
    
    h2 {
      font-size: 1.4rem;
      //padding-right: 20px;
      margin-bottom: 0;
      font-weight: 600;
    }

    .small_header { margin-top: 0; }
    .full_header { display: none; }

    .note_header_text {
      font-size: 1.5rem;
      font-weight: 600;
    }

    .datepicker {
      position: relative;
      background: transparent;
      border: 0;
      width: inherit;
      padding: 0 1rem 0 0;
      font-size: 1.5rem;
      font-weight: 600;
      z-index: 2;
    }

    .edit_icon {
      top: 1.5rem!important;
      transform: translateY(0)!important;
    }

    .note_name {
      padding-left: 10px;

      h2 {
        display: inline-block;
        width: 95%;
      }

      input { width: 100%; }
    }

    .note_name,
    .note_date {
      position: relative;
      flex: 0 0 50%;
      max-width: 50%;
    }
  }

  .template_content {
    background-color: white;
    padding-top: 15px;
    padding-bottom: 15px;

    &:first-child {
      margin-bottom: 1.5rem;
    }
  }

  .no_pdf_settings { display: none; }

  .block_list {
    margin-bottom: 4rem;

    &:last-child { margin-bottom: 0; }
  }

  .section_name {
    font-size: 1.5rem;
    font-weight: 600;
  }

  .message_box {
    display: none;
    background-color: $yellow;
    color: white;
    padding: 1.2rem 1.2rem;
    margin-bottom: 1.5rem;
    font-weight: 700;
    font-size: 0.88rem;
  }

  .message_box_open {
    display: block;
  }

  .message_box_container {
    position: relative;
  }

  .message_box_message {
    display: inline-block;
    width: 68%;
  }

  .message_box_close {
    @include vertical-align-middle;
    right: 0;
    text-transform: uppercase;
    border-bottom: 1px solid white;
    cursor: pointer;
    line-height: 1.1;
  }

  .widget_box {
    background-color: white;
    margin-bottom: 1.5rem;
    
    .widget_name {
      border-bottom: 1px solid $border-grey;
      padding: 1.1rem 1.3rem;
      font-size: 1.3rem;
      font-weight: 600;

      .icon_font {
        font-size: 0.8rem;
        float: right;
        padding: 0.5rem 0 0.5rem;
        cursor: pointer;
      }
    }
  }

  .template_sidebar {
    position: relative;
    //width: 195px;
    width: inherit;
    //max-width: 300px;
  }

  .template_sidebar_container {
    /*position: fixed;
    right: 15px;
    width: 28%;
    overflow: hidden;*/
  }
}

@media (min-width:1024px) {
  .template {
    .template_content {
      padding: 2.3rem;
    }

    .note_header {
      padding: 2rem 1rem;

      .full_header {
        display: block;
      }
  
      .mobile_header {
        display: none;
      }

      .note_name {
        flex: 0 0 100%;
        max-width: 100%;
        margin-bottom: 1rem;
      }

      .note_date {
        padding-left: 10px;
      }
  
      .note_date {
        flex: 0 0 45%;
        max-width: 45%;
      }

      .full_header {
        flex: 0 0 50%;
        max-width: 50%;
      }
    }
  }

  .message_box_message {
    display: inline-block;
    width: 70%;
  }

  .template_sidebar {
    position: relative;
    width: 34%;
    max-width: 34%;
  }
}

@media (min-width:1200px) {
  .template {
    .note_header {
      h2 {
        font-size: 1.9rem;
        padding-right: 20px;
        margin-bottom: 0;
        font-weight: 600;
      }
    }
  }
}

@media (min-width:1500px) {
  .template {
    .note_header {
      .note_name {
        flex: 0 0 50%;
        max-width: 50%;
        margin-bottom: 0;
      }

      .note_date {
        padding-left: 0;
      }
  
      .note_date {
        flex: 0 0 30%;
        max-width: 30%;
      }

      .full_header {
        flex: 0 0 20%;
        max-width: 20%;
      }
    }
  }
}