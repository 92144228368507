.input-timepicker{
  input { width: 200px; }
}

.react-time-picker__inputGroup__input {
  padding: 5px;
  width: 15px!important;
  min-width: 0.54em;
  height: 100%;
  position: relative;
  padding: 0 1px;
  border: 0;
  background: none;
  font: inherit;
  box-sizing: content-box;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
}

.react-time-picker__inputGroup__divider {
  padding-right: 1px;
}