.no_gutter {
  padding-left: 0!important;
  padding-right: 0!important;
}

:global(hr) {
  border-color: $border-grey;
}

:global(.text_uppercase) {
  text-transform: uppercase;
}

:global(.colour-teal) {
  color: $teal;
}

:global(.btn-primary) {
  background-color: $teal;
  color: white;
  border-color: transparent;
}

:global(.btn.disabled) {
  background-color: #D5D8DA;
  color: #91999E;
}

:global(.icon-small)  {
  margin-left: .5rem;
  font-size: 0.6rem;
  vertical-align: baseline;
}

h4 {
  font-size: 1.08rem;
  font-weight: 600;
}

.semi-bold {
  font-weight: 600;
}

.message-box {
  position: relative;
  background: rgb(246,181,21);
  background: linear-gradient(90deg, rgba(246,181,21,1) 0%, rgba(246,150,21,1) 100%);
  padding: 2rem;
  margin-bottom: 1rem;
  color: white;
  font-size: 0.9rem;

  a {
    color: white!important;
    text-decoration: underline;
  }

  .col-left {
    align-self: center;
  }

  .col-right {
    text-align: right;
  }

  .upgrade-btn {
    background-color: transparent!important;
    border: 2px solid white;
    padding: 0.75rem 0.7rem!important;
    //margin-right: 0.7rem;
    line-height: 1;
    text-transform: uppercase;
  }
}

.paper-format {
  @include vertical-align-middle();
}

.react-datepicker-wrapper ,
.react-datepicker__input-container {
  width: 100%; 
}

.react-datepicker-popper {
  width: 400px;
}

.react-datepicker-wrapper + .icon-Edit {
  //z-index: -1;
}

.login-container {
  @include vertical-align-middle;
  width: 100%;
  left: 0;

  .login-wrapper {
    max-width: 450px;
    margin: 0 auto;
  }

  .login-logo {
    margin-bottom: 3.2rem;
    text-align: center;
  }

  .login-box {
    background-color: white;
    padding: 2.5rem;
    margin-bottom: 2rem;

    h2 { font-weight: 600; }

    input {
      width: 100%;
    }

    input[type="password"] {
      margin-bottom: 1rem;
    }

    button {
      margin-top: 3rem;
      width: 200px;
    }

    a { 
      text-decoration: underline;
    }
  }

  .login-links {
    margin-bottom: 4rem;
    color: white;

    button {
      background-color: transparent;
      margin-left: 1rem;
      padding: 0.5rem 1.3rem;
      color: $teal;
      border: 2px solid $teal;
    }
  }

  .footer-links {
    text-align: center;

    ul {
      margin: 0;
      padding: 0;

      li {
        display: inline-block;

        &:before {
          content: '\00B7';
          color: white;
          margin: 0 0.5rem;
        }

        &:first-child:before,
        &:last-child:after {
          display: none;
        }
      }
    }

    a { 
      color: white;
    }
  }
}

.icon-Triangle.reverse {
  transform: rotate(180deg);
}

.content-editable {
  overflow-y: scroll;
  padding: 20px 15px;
  max-height: 170px;
  border: 1px solid $border-grey;
  -webkit-touch-callout: all; /* iOS Safari */
  -webkit-user-select: all; /* Safari */
  -khtml-user-select: all; /* Konqueror HTML */
  -moz-user-select: all; /* Firefox */
  -ms-user-select: all; /* Internet Explorer/Edge */
  user-select: all; /* Chrome and Opera */

  &.plain-text {
    font-family: 'Courier New', Courier, monospace;
  }

  h1 { font-size: 130%; }
  h2 { font-size: 110%; }
  h3 { font-size: 100%; }

  h1,
  h2,
  h3,
  h4 {
    font-weight: bold;
    text-decoration: underline;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    margin: 0;
  }
}