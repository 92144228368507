.checkbox-input {
  position: relative;

  input[type="checkbox"] {
    position: absolute;
    opacity: 0;
    width: 16px;
    height: 16px;
    top: 3px;
    z-index: 3;
  }

  label {
    position: relative;
    display: inline-block;
    padding-left: 28px;
    //margin-bottom: 0.2rem;
    vertical-align: top;

    &::before{
      content: '';
      background-color: $light-white;
      display: inline-block;
      height: 16px;
      width: 16px;
      top: 3px;
      left: 0;
    }

    &::after {
      content: '\e902';
      display: inline-block;
      font-family: 'icomoon' !important;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      font-size: 0.84rem;
      line-height: 1;
      top: 5px;
      left: 2px;
      color: white;
      height: 16px;
      width: 16px;
    }
  }

  label::before,
  label::after {
    position: absolute;
  }

  input[type="checkbox"] + label::after {
    content: none;
  }

  input[type="checkbox"]:checked + label::after {
    content: '\e902'!important;
  }

  input[type="checkbox"]:checked + label::before {
    background-color: $teal;
  }
}

.custom-templates {
  .checkbox-input {
    input[type="checkbox"]:checked + label::before {
      background-color: $yellow;
    }
  }
}